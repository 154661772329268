import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { carregarUsuario } from '../../auth/authActions';

class Menu extends Component {

	state = {
		dashboardAberto: null,
		acessoAberto: null,
		cadastroAberto: null,
		precoAberto: null,
		vendaAberto: null,
		logisticaAberto: null,
		estoqueAberto: null,
		metaAberto: null,
		manutencaoAberto: null
	};

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.usuario) {
				this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		if (this.props.usuarioCarregado) {
			if (!this.props.usuarioCarregado.acesso_usuario && (selecionado == '/usuario' || selecionado == '/usuarioPerfil')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_cadastro && (selecionado == '/unidade' || selecionado == '/motorista' || selecionado == '/motoristaDocumento'
				|| selecionado == '/veiculoTipo' || selecionado == '/veiculo' || selecionado == '/veiculoDocumento' || selecionado == '/produto' 
				|| (selecionado == '/distribuidora' && !this.props.usuarioCarregado.acesso_preco_dia)  || selecionado == '/tanque' 
				|| selecionado == '/cliente' || selecionado == '/feedbackMotoristaTipo' || selecionado == '/feedbackMotorista')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_preco_dia && (selecionado == '/precoDia' || selecionado == '/analiseCurvaPreco' || selecionado == '/adicionalDiario' 
				|| selecionado == '/freteLitro' || selecionado == '/custoPrazo'  || selecionado == '/precoGeral' 
				|| selecionado == '/politicaPreco')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_politica && (selecionado == '/acesso_politica')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_vendas && (selecionado == '/pedido')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_pedido_aprovacao && (selecionado == '/pedidoAprovacao')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_logistica && (selecionado == '/pedidoFinalizado' || selecionado == '/pedidoEntrega' 
				|| selecionado == '/entrada' || selecionado == '/saida' || selecionado == '/transferencia')) {
				if (!this.props.usuarioCarregado.acesso_operacao_abastecida || selecionado != '/saida' || selecionado == '/testeQualidade'
					|| selecionado == '/ocorrenciaLogistica' || selecionado == '/telemetria' || selecionado == '/tempoDirecao') {

					if (!this.props.usuarioCarregado.acesso_pedido_aprovacao && !this.props.usuarioCarregado.acesso_vendas) {
						window.location = '/';
					}
				}
			}

			if (!this.props.usuarioCarregado.acesso_vendas && !this.props.usuarioCarregado.acesso_logistica 
				&& (selecionado == '/pedido' || selecionado == 'pedidoLogistica')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_meta_motorista
				&& (selecionado == '/setor' || selecionado == '/pedidoFinapontamentoGrupoalizado' || selecionado == '/apontamentoTipo' 
				|| selecionado == '/apontamento' || selecionado == '/veiculoTipoConsumo' || selecionado == '/metaMotoristaGrupo'
				|| selecionado == '/metaVendedor' || selecionado == '/metaVendedorResultado')) {
				window.location = '/';
			}

		}

		if (this.state.dashboardAberto == null && this.state.acessoAberto == null && this.state.cadastroAberto == null
			&& this.state.precoAberto == null && this.state.vendaAberto == null && this.state.logisticaAberto == null 
			&& this.state.estoqueAberto == null && this.state.metaAberto == null && this.state.manutencaoAberto == null) {

			if (selecionado == '/' || selecionado == '/dashboardPedido' || selecionado == '/dashboardPetroshow' || selecionado == '/metaVendaDashboard' 
				|| selecionado == '/metaVendaDashboardGerencia' || selecionado == '/veiculoDashboard' || selecionado == '/veiculoDashboardKmRodado'
				|| selecionado == '/veiculoDespesa') {
				this.setState({
					...this.state,
					dashboardAberto: true
				});
			}

			if (selecionado == '/usuarioPerfil' || selecionado == '/usuario') {
				this.setState({
					...this.state,
					acessoAberto: true
				});
			}
			
			if (selecionado == '/unidade' || selecionado == '/motorista' || selecionado == '/motoristaDocumento' 
				|| selecionado == '/veiculoTipo' || selecionado == '/veiculo' || selecionado == '/veiculoDocumento'
				|| selecionado == '/produto' || selecionado == '/distribuidora' || selecionado == '/tanque' || selecionado == '/cliente'
				|| selecionado == '/feedbackMotoristaTipo' || selecionado == '/feedbackMotorista') {
				this.setState({
					...this.state,
					cadastroAberto: true
				});
			}

			if (selecionado == '/precoDia' || selecionado == '/analiseCurvaPreco' || selecionado == '/adicionalDiario' || selecionado == '/freteLitro'
				|| selecionado == '/custoPrazo' || selecionado == '/precoGeral' || selecionado == '/politicaPreco') {
				this.setState({
					...this.state,
					precoAberto: true
				});
			}
						
			if (selecionado == '/pedido' || selecionado == '/pedidoAprovacao' || selecionado == '/clienteMapa' || selecionado == '/pontoMapa'
				|| selecionado == '/prospeccao' || selecionado == '/clienteCRM' || selecionado == '/clienteCRMHistorico'
				|| selecionado == '/whatsapp' || selecionado == '/limiteCredito') {
				this.setState({
					...this.state,
					vendaAberto: true
				});
			}
	
			if (selecionado == '/pedidoLogistica' || selecionado == '/pedidoFinalizado' || selecionado == '/pedidoEntrega' || selecionado == '/pedidoEstoque'
				|| selecionado == '/entrada' || selecionado == '/saida' || selecionado == '/transferencia' || selecionado == '/testeQualidade'
				|| selecionado == '/ocorrenciaLogistica' || selecionado == '/telemetria' || selecionado == '/tempoDirecao') {
				this.setState({
					...this.state,
					logisticaAberto: true
				});
			}

			if (selecionado == '/relatorioMovimentos' || selecionado == '/estoqueManual' || selecionado == '/estoquePetroshow') {
				this.setState({
					...this.state,
					estoqueAberto: true
				});
			}	

			if (selecionado == '/setor' || selecionado == '/pedidoFinapontamentoGrupoalizado' || selecionado == '/apontamentoTipo' 
				|| selecionado == '/apontamento' || selecionado == '/veiculoTipoConsumo' || selecionado == '/metaMotoristaGrupo'
				|| selecionado == '/metaVenda') {
				this.setState({
					...this.state,
					metaAberto: true
				});
			}

			if (selecionado == '/oficina' || selecionado == '/manutencao' || 
				selecionado == '/manutencaoPreventivaTipo' || selecionado == '/manutencaoPreventiva') {
				this.setState({
					...this.state,
					manutencaoAberto: true
				});
			}

		}
		
		
		return (
			<nav className="mt-2">
								    
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					<MenuTree icon={'fas fa-columns'} label={'Dashboards'}
						display={this.state.dashboardAberto}
						onClick={() =>
							this.setState({
								...this.state,
								dashboardAberto: !this.state.dashboardAberto,
							})
						} >					

						<MenuItem path='/' label='Saldos' icon='far fa-chart-bar' active={selecionado == '/'} />
						<MenuItem path='dashboardPedido' label='Dashboard Pedido' icon='fas fa-chart-line' active={selecionado == '/dashboardPedido'} />
						
						{(this.props.usuarioCarregado && (this.props.usuarioCarregado.id == 1 || this.props.usuarioCarregado.id == 4 || this.props.usuarioCarregado.id == 30)) && //Pedro e Ronivon
						<MenuItem path='dashboardPetroshow' label='Infinitum X Petroshow' icon='fas fa-tasks' active={selecionado == '/dashboardPetroshow'} />}

						{(this.props.usuarioCarregado && 
							(
								this.props.usuarioCarregado.acesso_vendas 
								|| this.props.usuarioCarregado.id == 51 /*TV*/
								|| this.props.usuarioCarregado.acesso_meta_motorista
							)
						) &&
						<MenuItem path='metaVendaDashboard' label='Dashboard Meta de Venda' icon='fas fa-comments-dollar' active={selecionado == '/metaVendaDashboard'} />}

						{(this.props.usuarioCarregado && (this.props.usuarioCarregado.id == 1 || this.props.usuarioCarregado.id == 4 || this.props.usuarioCarregado.id == 56)) &&
						<MenuItem path='metaVendaDashboardGerencia' label='Dashboard Meta Gerência' icon='fas fa-comment-dollar' active={selecionado == '/metaVendaDashboardGerencia'} />}
						
						{(this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_vendas || this.props.usuarioCarregado.acesso_logistica || this.props.usuarioCarregado.id == 51 /*TV*/)) &&
						<MenuItem path='veiculoDashboard' label='Dashboard Frota' icon='fas fa-shipping-fast' active={selecionado == '/veiculoDashboard'} />}

						{(this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_vendas || this.props.usuarioCarregado.acesso_logistica || this.props.usuarioCarregado.id == 51 /*TV*/)) &&
						<MenuItem path='veiculoDashboardKmRodado' label='Eficiência da Frota' icon='fas fa-tachometer-alt' active={selecionado == '/veiculoDashboardKmRodado'} />}
						
						{(this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_vendas || this.props.usuarioCarregado.acesso_logistica || this.props.usuarioCarregado.id == 51 /*TV*/)) &&
						<MenuItem path='veiculoDespesa' label='Custos da Frota' icon='fas fa-search-dollar' active={selecionado == '/veiculoDespesa'} />}

					</MenuTree>

					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.acesso_usuario
					) &&
					<MenuTree icon={'fas fa-user'} label={'Acesso'}
						display={this.state.acessoAberto}
						onClick={() =>
							this.setState({
								...this.state,
								acessoAberto: !this.state.acessoAberto,
							})
						} >
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_usuario ? (
							<MenuItem submenu path='usuarioPerfil' label='Perfil de Usuário' icon='fas fa-user-tag' active={selecionado == '/usuarioPerfil'} />
						) :null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_usuario ? (
							<MenuItem submenu path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
						) : null}
					</MenuTree>}

					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.acesso_cadastro  
						|| this.props.usuarioCarregado.acesso_preco_dia
					) &&
					<MenuTree icon={'fas fa-th-list'} label={'Cadastro'}
						display={this.state.cadastroAberto}
						onClick={() =>
							this.setState({
								...this.state,
								cadastroAberto: !this.state.cadastroAberto,
							})
						} >
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='unidade' label='Unidade' icon='fas fa-building' active={selecionado == '/unidade'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='motorista' label='Motorista' icon='fas fa-user-plus' active={selecionado == '/motorista'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='motoristaDocumento' label='Motorista - Documentos' icon='fas fa-address-card' active={selecionado == '/motoristaDocumento'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='veiculoTipo' label='Tipo de Veículo' icon='fas fa-truck-loading' active={selecionado == '/veiculoTipo'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='veiculo' label='Veículo' icon='fas fa-truck' active={selecionado == '/veiculo'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='veiculoDocumento' label='Veículo - Documentos' icon='fas fa-id-card' active={selecionado == '/veiculoDocumento'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='produto' label='Produto' icon='fas fa-list' active={selecionado == '/produto'} />
						) : null}
						{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_cadastro || this.props.usuarioCarregado.acesso_preco_dia) ? (
							<MenuItem submenu path='distribuidora' label='Distribuidora' icon='fas fa-industry' active={selecionado == '/distribuidora'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='tanque' label='Tanque' icon='fas fa-gas-pump' active={selecionado == '/tanque'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='cliente' label='Cliente' icon='fas fa-user-edit' active={selecionado == '/cliente'} />
						) : null}

						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='feedbackMotoristaTipo' label='Feedback - Tipo' icon='fas fa-list-alt' active={selecionado == '/feedbackMotoristaTipo'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='feedbackMotorista' label='Feedback - Motorista' icon='fas fa-comments' active={selecionado == '/feedbackMotorista'} />
						) : null}
					</MenuTree>}

					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.acesso_preco_dia  
						|| this.props.usuarioCarregado.acesso_politica
					) &&
					<MenuTree icon={'fas fa-hand-holding-usd'} label={'Precifição'}
						display={this.state.precoAberto}
						onClick={() =>
							this.setState({
								...this.state,
								precoAberto: !this.state.precoAberto,
							})
						} >
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_preco_dia ? (
							<MenuItem submenu path='precoDia' label='Preço do Dia' icon='fas fa-receipt' active={selecionado == '/precoDia'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_preco_dia ? (
							<MenuItem submenu path='analiseCurvaPreco' label='Análise Curva de Preço' icon='fas fa-stream' active={selecionado == '/analiseCurvaPreco'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_preco_dia ? (
							<MenuItem submenu path='adicionalDiario' label='Adicional Diário' icon='fas fa-money-check' active={selecionado == '/adicionalDiario'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_preco_dia ? (
							<MenuItem submenu path='freteLitro' label='Valor Frete' icon='fas fa-road' active={selecionado == '/freteLitro'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_preco_dia ? (
							<MenuItem submenu path='custoPrazo' label='Custo do Prazo' icon='fas fa-calendar' active={selecionado == '/custoPrazo'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_preco_dia ? (
							<MenuItem submenu path='precoGeral' label='Preço Geral' icon='fas fa-sort-amount-down' active={selecionado == '/precoGeral'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_politica ? (
							<MenuItem submenu path='politicaPreco' label='Política de Preços' icon='fas fa-funnel-dollar' active={selecionado == '/politicaPreco'} />
						) : null}
					</MenuTree>}

					<MenuTree icon={'fas fa-money-check'} label={'Venda'}
						display={this.state.vendaAberto}
						onClick={() =>
							this.setState({
								...this.state,
								vendaAberto: !this.state.vendaAberto,
							})
						} >
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_vendas ? (
							<MenuItem submenu path='pedido' label='Pedidos' icon='fas fa-clipboard-list' active={selecionado == '/pedido'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_pedido_aprovacao ? (
							<MenuItem submenu path='pedidoAprovacao' label='Giro - Aprovar' icon='fas fa-clipboard-check' active={selecionado == '/pedidoAprovacao'} />
						) : null}
						{(this.props.usuarioCarregado && 
								(this.props.usuarioCarregado.acesso_vendas
								|| this.props.usuarioCarregado.acesso_pedido_aprovacao 
								|| this.props.usuarioCarregado.acesso_logistica)) &&
						<MenuItem path='clienteMapa' label='Mapa' icon='fas fa-map' active={selecionado == '/clienteMapa'} />}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.id != 51 /*TV*/ &&
						<MenuItem path='pontoMapa' label='Ponto' icon='fas fa-map-marker-alt' active={selecionado == '/pontoMapa'} />}
						{/* <MenuItem path='veiculoStatus' label='Mapa de Veículos' icon='fas fa-truck' active={selecionado == '/veiculoStatus'} /> */}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.id != 51 /*TV*/ &&
						<MenuItem path='prospeccao' label='Prospecção' icon='fas fa-clipboard-list' active={selecionado == '/prospeccao'} />}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.id != 51 /*TV*/ &&
						<MenuItem path='clienteCRM' label='Cliente - Vendas' icon='fas fa-headphones' active={selecionado == '/clienteCRM'} />}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.id != 51 /*TV*/ &&
						<MenuItem path='clienteCRMHistorico' label='Contatos - Histórico' icon='fas fa-list' active={selecionado == '/clienteCRMHistorico'} />}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.id != 51 /*TV*/ &&
						<MenuItem path='whatsapp' label='WhatsApp Histórico' icon='fab fa-whatsapp' active={selecionado == '/whatsapp'} />}
						{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_cadastro || this.props.usuarioCarregado.id_perfil == 16 //Limite de Crédito  
							|| this.props.usuarioCarregado.acesso_vendas) ? (
						<MenuItem submenu path='limiteCredito' label='Limite de Crédito' icon='fas fa-search-dollar' active={selecionado == '/limiteCredito'} />
						) : null}
					</MenuTree>

					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.acesso_vendas  
						|| this.props.usuarioCarregado.acesso_pedido_aprovacao 
						|| this.props.usuarioCarregado.acesso_logistica
						|| this.props.usuarioCarregado.acesso_operacao_abastecida
					) &&
					<MenuTree icon={'fas fa-truck'} label={'Logística'}
						display={this.state.logisticaAberto}
						onClick={() =>
							this.setState({
								...this.state,
								logisticaAberto: !this.state.logisticaAberto,
							})
						} >
						{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_vendas || this.props.usuarioCarregado.acesso_logistica) ? (
							<MenuItem path='pedidoLogistica' label='Giro - Aprovado' icon='fas fa-paste' active={selecionado == '/pedidoLogistica'} />
						) : null}
						{this.props.usuarioCarregado && 
							(this.props.usuarioCarregado.acesso_vendas
							|| this.props.usuarioCarregado.acesso_pedido_aprovacao 
							|| this.props.usuarioCarregado.acesso_logistica) ? (
							<MenuItem submenu path='pedidoFinalizado' label='Giro - Finalizado' icon='fas fa-check-double' active={selecionado == '/pedidoFinalizado'} />
						) : null}
						{this.props.usuarioCarregado && 
							(this.props.usuarioCarregado.acesso_vendas
							|| this.props.usuarioCarregado.acesso_pedido_aprovacao 
							|| this.props.usuarioCarregado.acesso_logistica) ? (
							<MenuItem submenu path='pedidoEntrega' label='Giro - Entrega' icon='fas fa-road' active={selecionado == '/pedidoEntrega'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_logistica ? (
							<MenuItem submenu path='pedidoEstoque' label='Giro - Estoque' icon='fas fa-clipboard-list' active={selecionado == '/pedidoEstoque'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_logistica ? (
							<MenuItem submenu path='entrada' label='Entrada' icon='fas fa-plus-square' active={selecionado == '/entrada'} />
						) : null}
						{this.props.usuarioCarregado && (
							this.props.usuarioCarregado.acesso_logistica || this.props.usuarioCarregado.acesso_operacao_abastecida
						) ? (
							<MenuItem submenu path='saida' label='Saída' icon='fas fa-check' active={selecionado == '/saida'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_logistica ? (
							<MenuItem submenu path='transferencia' label='Transferência' icon='fas fa-random' active={selecionado == '/transferencia'} />
						) : null}
						<MenuItem submenu path='testeQualidade' label='Teste de Qualidade' icon='fas fa-vial' active={selecionado == '/testeQualidade'} />
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_logistica ? (
							<MenuItem submenu path='ocorrenciaLogistica' label='Ocorrências - Logística' icon='fas fa-exclamation-triangle' active={selecionado == '/ocorrenciaLogistica'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_logistica ? (
							<MenuItem submenu path='telemetria' label='Telemetria' icon='fas fa-road' active={selecionado == '/telemetria'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_logistica ? (
							<MenuItem submenu path='tempoDirecao' label='Tempo de Direção' icon='fas fa-road' active={selecionado == '/tempoDirecao'} />
						) : null}
					</MenuTree>}

					<MenuTree icon={'fas fa-glass-whiskey'} label={'Estoque'}
						display={this.state.estoqueAberto}
						onClick={() =>
							this.setState({
								...this.state,
								estoqueAberto: !this.state.estoqueAberto,
							})
						} >
						
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_logistica ? (
							<MenuItem submenu path='relatorioMovimentos' label='Movimentos' icon='fas fa-receipt' active={selecionado == '/relatorioMovimentos'} />
						) : null}

						{(this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_operacao_abastecida) &&
						<MenuItem path='estoqueManual' label='Manual' icon='fas fa-pencil-ruler' active={selecionado == '/estoqueManual'} />}
					
						{(this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_logistica)) &&
						<MenuItem path='estoquePetroshow' label='Petroshow' icon='fas fa-ruler-vertical' active={selecionado == '/estoquePetroshow'} />}
						
					</MenuTree>

					{/*<MenuTree icon={'fas fa-dot-circle'} label={'Metas - Vendedor'}
						display={this.state.metaVendedorAberto}
						onClick={() =>
							this.setState({
								...this.state,
								metaVendedorAberto: !this.state.metaVendedorAberto,
							})
						} >
						<MenuItem path='metaVendedor' label='Meta' icon='fas fa-users' active={selecionado == '/metaVendedor'} />
						<MenuItem path='metaVendedorResultado' label='Resultado' icon='far fa-chart-bar' active={selecionado == '/metaVendedorResultado'} />
					</MenuTree>*/}
				
					{/*this.props.usuarioCarregado && this.props.usuarioCarregado.id != 51 /*TV*/ /*&&
					<MenuItem path='checklist' label='Checklist Configuração' icon='fas fa-tasks' active={selecionado == '/checklist'} />*/}
															
					<MenuTree icon={'fas fa-bullseye'} label={'Metas'}
						display={this.state.metaAberto}
						onClick={() =>
							this.setState({
								...this.state,
								metaAberto: !this.state.metaAberto,
							})
						} >
						{/*<MenuItem path='setor' label='Setor' icon='fas fa-users' active={selecionado == '/setor'} />*/}
						{/*<MenuItem path='apontamentoGrupo' label='Grupo do Apontamento' icon='fas fa-bars' active={selecionado == '/apontamentoGrupo'} />*/}
						{/*<MenuItem path='apontamentoTipo' label='Tipo do Apontamento' icon='fas fa-list-ol' active={selecionado == '/apontamentoTipo'} />*/}
						{this.props.usuarioCarregado && (
							this.props.usuarioCarregado.acesso_meta_motorista
						) &&
						<MenuItem path='veiculoTipoConsumo' label='Parâmetro de Consumo' icon='fas fa-list' active={selecionado == '/veiculoTipoConsumo'} />}
						{/*<MenuItem path='apontamento' label='Apontamento' icon='fas fa-paste' active={selecionado == '/apontamento'} />*/}
						{/*<MenuItem path='metaMotoristaGrupo' label='Grupo do Motorista' icon='fas fa-th' active={selecionado == '/metaMotoristaGrupo'} />*/}
						{this.props.usuarioCarregado && (
							this.props.usuarioCarregado.acesso_meta_motorista
						) &&
						<MenuItem path='metaMotorista' label='Meta do Motorista' icon='fas fa-paste' active={selecionado == '/metaMotorista'} />}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.id != 51 /*TV*/ &&
						<MenuItem path='metaVenda' label='Meta de Venda' icon='fas fa-tasks' active={selecionado == '/metaVenda'} />}
					</MenuTree>

					{(this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro) &&
					<MenuTree icon={'fas fa-wrench'} label={'Manutenção'}
						display={this.state.manutencaoAberto}
						onClick={() =>
							this.setState({
								...this.state,
								manutencaoAberto: !this.state.manutencaoAberto,
							})
						} >

						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='oficina' label='Oficina' icon='fas fa-warehouse' active={selecionado == '/oficina'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='manutencao' label='Manutenção' icon='fas fa-wrench' active={selecionado == '/manutencao'} />
						) : null}

						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='manutencaoPreventivaTipo' label='Tipo da Preventiva' icon='fas fa-tools' active={selecionado == '/manutencaoPreventivaTipo'} />
						) : null}

						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
							<MenuItem submenu path='manutencaoPreventiva' label='Manutenção Preventiva' icon='fas fa-calendar-check' active={selecionado == '/manutencaoPreventiva'} />
						) : null}			

					</MenuTree>}

					{/*this.props.usuarioCarregado && this.props.usuarioCarregado.id_perfil == 2 &&
					<MenuItem path='chamado' label='Solicitação de Melhorias' icon='fas fa-hands-helping' active={selecionado == '/chamado'} />*/}
					
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarUsuario }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
