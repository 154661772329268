const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaMotorista: [],
    listaVeiculo: [],
    listaEvento: [
        { id: 235, valor: 'ARRANCADA BRUSCA' },
        { id: 237, valor: 'BANGUELA' },
        { id: 227, valor: 'EXCESSO DE ROTAÇÃO' },
        { id: 229, valor: 'EXCESSO VELOCIDADE TRECHO RODOVIARIO SECO' },
        { id: 214, valor: 'FORÇA G LATERAL FRACA' },
        { id: 236, valor: 'FREADA BRUSCA' },
        { id: 221, valor: 'PRÉ-INFRAÇÃO DE EXCESSO DE VELOCIDADE SECO' },
        { id: 234, valor: 'PÉ NA EMBREAGEM' },
        { id: 210, valor: 'SISTEMA DE FREIO A AR' },
        { id: 223, valor: 'TEMPO PARADO' }
    ]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'TELEMETRIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'TELEMETRIA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'TELEMETRIA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
            };

        case 'TELEMETRIA_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
            };
                      
        default:
            return state;
    }
}
