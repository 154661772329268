import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, setCliente } from '../../auth/authActions';
import DateFormat from '../dateFormat/DateFormat';
import Row from '../layout/row';
import LabelAndInputMask from '../form/labelAndInputMask';
import Select from '../form/select';
import Grid from '../layout/grid';
import Button from '../button/button';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			open: false,
			relogio: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
			calculadora: false,
			dataBaseCalculadora: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
			dataPrazoCalculadora: null,
			diasPrazoCalculado: null,
			modoCalculadora: 'dias',
			prazosCalculadora: [],
			erroCalculadora: null,
			datasCalculadaCalculadora: null
		};
	}
	changeOpen() {
		this.setState({ 
			...this.state,
			open: !this.state.open 
		});
	}

	componentWillMount() {
		setInterval(() => {
			this.setState({
				...this.state,
				relogio: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
			});
		}, 30000)
	}

	render() {
		const { nome, login } = this.props.usuario;

		return (
			<ul className="navbar-nav ml-auto">

				{this.modalCalculadora()}

				<li className='nav-item dropdown'>
					<a className='nav-link'
						data-toggle='dropdown'
						href='#'
						onClick={() => {
							this.setModalCalculadora(true);
						}}>
						<i class='fas fa-calculator'></i>
					</a>
				</li>
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-clock" style={{ marginRight: 4 }}></i>{this.state.relogio}
					</a>
				</li>
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-user"></i>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">Perfil</span>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item">
							<i className="fas fa-user mr-2"></i> Nome
							<span className="float-right text-muted text-sm">{nome}</span>
						</a>
					<div className="dropdown-divider"></div>
					<a href="#" className="dropdown-item">
						<i className="fas fa-envelope mr-2"></i> Usuário
						<span className="float-right text-muted text-sm">{login}</span>
					</a>
					<div className="dropdown-divider"></div>
						<a href="#" onClick={this.props.logout}
							className="btn btn-block btn-primary btn-sm">Sair</a>
					</div>
				</li>
			</ul>
		);
	}

	setModalCalculadora(aberto) {
		this.setState({
			...this.state,
			calculadora: aberto
		});
	}

	calcularPrazo(dataBaseCalculadora, modoCalculadora, prazosCalculadora, dataPrazoCalculadora) {

		try {

			if (modoCalculadora == 'data') {

				let dataBase = new Date(`${DateFormat.formatarDataTelaParaSql(dataBaseCalculadora)} 12:00`);
				let dataPrazo = new Date(`${DateFormat.formatarDataTelaParaSql(dataPrazoCalculadora)} 12:00`);

				if (!dataBase || !dataPrazo) {
					this.setState({
						...this.state,
						erroCalculadora: 'Informe as datas',
						datasCalculadaCalculadora: null
					});
				} else if (dataBase > dataPrazo) {
					this.setState({
						...this.state,
						erroCalculadora: 'Data base está menor',
						datasCalculadaCalculadora: null
					});
				} else {
					this.setState({
						...this.state,
						diasPrazoCalculado: parseInt((dataPrazo.getTime() - dataBase.getTime()) / 1000 / 60 / 60 / 24),
						datasCalculadaCalculadora: null
					});
				}

			} else {
			
				let prazos = (prazosCalculadora || []).length ? (prazosCalculadora || []) : [1];

				let erro = null;
				let datas = prazos.map(prazo => {

					let data = new Date(`${DateFormat.formatarDataTelaParaSql(dataBaseCalculadora)} 12:00`);
					data.setDate(data.getDate() + prazo);

					let dataCalculada = DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(data.getTime()));

					if (!dataCalculada) {
						erro = 'Confira a data base e o prazo';
					} else {
						return {
							prazo: prazo,
							data: dataCalculada
						};
					}

				});

				this.setState({
					...this.state,
					datasCalculadaCalculadora: !erro ? datas : null,
					erroCalculadora: erro,
					diasPrazoCalculado: null
				});
		
			}

		} catch(ex) {
			console.log(ex);
			this.setState({
				...this.state,
				datasCalculadaCalculadora: null,
				erroCalculadora: 'Confira a data base e o prazo'
			});
		}
	}

	modalCalculadora() {

		if (!this.state.calculadora) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-md' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Calculadora de Prazo</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setModalCalculadora(false)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{  }}>
							<Row>
								<LabelAndInputMask
									label='Data Base' placeholder='Informe a data'
									cols='12 12 12 12'
									mask='99/99/9999'
									value={this.state.dataBaseCalculadora}
									onChange={data => {
										this.setState({
											...this.state,
											dataBaseCalculadora: data.target.value
										});
									}} />
								
								<Grid cols='12 12 12 12'>
									<button 
										type='button'
										class='btn btn-block btn-lg btn-secondary'
										style={{ marginBottom: 12 }}
										onClick={() => {
											this.setState({
												...this.state,
												modoCalculadora: this.state.modoCalculadora == 'data' ? 'dias' : 'data'
											});
										}}>
										<i class={`fas ${this.state.modoCalculadora == 'data' ? 'fa-calendar-day' : 'fa-calendar-alt'}`} style={{ marginRight: 8 }}></i>
										{this.state.modoCalculadora == 'data' ? 'Modo Data' : 'Modo Dias'}
									</button>
								</Grid>

								{this.state.modoCalculadora == 'data' ? (
									<LabelAndInputMask
										label='Data Prazo' placeholder='Informe a data'
										cols='12 12 12 12'
										mask='99/99/9999'
										value={this.state.dataPrazoCalculadora}
										onChange={data => {
											this.setState({
												...this.state,
												dataPrazoCalculadora: data.target.value
											});
										}} />
								) : (
									<Select
										options={Array.from(Array(180).keys()).map(item => ({ id: item + 1, valor: `${item + 1} ${item == 0 ? 'dia' : 'dias'}` }))}
										label='Prazo'
										cols='12 12 12 12'
										placeholder='Selecione o prazo'
										value={this.state.prazosCalculadora}
										multiple
										onChange={values => {
											console.log(values);
											this.setState({
												...this.state,
												prazosCalculadora: (values || []).map(item => item.value)
											});
										}} />
								)}
							</Row>
							<Row alignCenter>
								<Grid cols='12 12 12 12'>
									<Button
										text='Calcular'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => {
											this.calcularPrazo(this.state.dataBaseCalculadora, this.state.modoCalculadora, this.state.prazosCalculadora, this.state.dataPrazoCalculadora);
										}} />
								</Grid>
							</Row>

							<Row alignCenter style={{ marginTop: 12 }}>
								{this.state.erroCalculadora &&
								<div class='alert alert-danger alert-dismissible'>
									<h5><i class='icon fas fa-check'></i> Erro ao Calcular</h5>
									{this.state.erroCalculadora}
								</div>}

								{(this.state.datasCalculadaCalculadora || []).length > 0 &&
								<div class='alert alert-success alert-dismissible'>
									<h5 style={{ textAlign: 'center' }}><i class='icon fas fa-check'></i> {(this.state.datasCalculadaCalculadora || []).length > 1 ? 'Prazos Calculados' : 'Prazo Calculado'}</h5>
									{(this.state.datasCalculadaCalculadora || []).map(item => (									
										<h5 key={item.prazo} style={{ textAlign: 'center', display: 'flex' }}><div style={{ width: 100, textAlign: 'right', paddingRight: 6 }}>{item.prazo} {item.prazo == 1 ? 'dia' : 'dias'}:</div> {item.data}</h5>
									))}
								</div>}

								{this.state.diasPrazoCalculado > 0 && 
								<div class='alert alert-success alert-dismissible'>
									<h5 style={{ textAlign: 'center' }}><i class='icon fas fa-check'></i> Prazo Calculado</h5>
									<h5 style={{ textAlign: 'center' }}>{this.state.diasPrazoCalculado} {this.state.diasPrazoCalculado == 1 ? 'dia' : 'dias'}</h5>
								</div>}
							</Row>
						</div>
						<div class='modal-footer justify-content-between' style={{ height: 2, padding: 0, margin: 0 }}>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout, setCliente }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className="navbar-custom-menu">
	<ul className="nav navbar-nav">
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href="javascript:;" onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className="dropdown-toggle"
				data-toggle="dropdown">
				<i className="fa fa-user"/>
				<span className="hidden-xs">{nome}</span>
			</a>
			<ul className="dropdown-menu">
				<li className="user-header">
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className="user-footer">
					<div className="pull-right">
						<a href="#" onClick={this.props.logout}
							className="btn btn-default btn-flat">Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
