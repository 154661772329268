import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, getLista, getListaVeiculo, getListaMotorista
} from './tempoDirecaoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';

class TempoDirecao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 604800000)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
        this.props.getLista(this.state.dataInicial, this.state.dataFinal);
		this.props.getListaVeiculo();
		this.props.getListaMotorista();
	}
	
	componentWillUnmount() {
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let filtroVeiculo = this.props.listaVeiculo.filter(item => item.id == this.state.id_veiculo)[0];
		let filtroMotorista = this.props.listaMotorista.filter(item => item.id == this.state.id_motorista)[0];

		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`
					${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio)}
					${item.nome_motorista || ''}
					${item.placa || ''}
					${item.descricao_evento_tempo_direcao || ''}
					${item.descricao_evento_tempo_direcao_anterior || ''}
					${item.odometro || ''}
					${item.cidade || ''}
					${item.uf || ''}
					${item.rua || ''}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if (filtroVeiculo && item.placa != filtroVeiculo.placa) {
				return false;
			}
			
			if (filtroMotorista && item.nome_motorista != filtroMotorista.valor) {
				return false;
			}

			if (this.state.id_evento && item.id_evento_tempo_direcao != this.state.id_evento) {
				return false;
			}

			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Tempo de Direcao'}
								element={
									<Button
									text='Exportar'
									type={'primary'}
									icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora_inicio: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio)
								}))} name='Tempo de Direcao'>
									<ExcelColumn label='Data Hora' value='datahora_inicio' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Placa' value='placa' />
									<ExcelColumn label='Evento Anterior' value='descricao_evento_tempo_direcao_anterior' />
									<ExcelColumn label='Evento Atual' value='descricao_evento_tempo_direcao' />
									<ExcelColumn label='Odômetro' value='odometro' />
									<ExcelColumn label='Cidade' value='cidade' />
									<ExcelColumn label='UF' value='uf' />
									<ExcelColumn label='Rua' value='rua' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>

				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />
						
						<Select
							options={this.props.listaVeiculo}
							label='Veículo'
							cols='12 6 2 2'
							placeholder='Selecione o veículo'
							value={this.state.id_veiculo}
							onChange={value => this.setState({ ...this.state, id_veiculo: value })} />

						<Select
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 6 2 2'
							placeholder='Selecione o motorista'
							value={this.state.id_motorista}
							onChange={value => this.setState({ ...this.state, id_motorista: value })} />

						<Select
							options={this.props.listaEvento}
							label='Evento'
							cols='12 6 2 2'
							placeholder='Selecione o evento'
							value={this.state.id_evento}
							onChange={value => this.setState({ ...this.state, id_evento: value })} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista(this.state.dataInicial, this.state.dataFinal);
								}} />
						</Grid>
					</Row>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Data Hora</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Placa</Th>
								<Th alignCenter>Evento Anterior</Th>
								<Th alignCenter>Evento Atual</Th>
								<Th alignCenter>Odômetro</Th>
								<Th alignCenter>Cidade</Th>
								<Th alignCenter>UF</Th>
								<Th alignCenter>Rua</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id}>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio)}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.placa}</Td>
									<Td alignCenter>{item.descricao_evento_tempo_direcao}</Td>
									<Td alignCenter>{item.descricao_evento_tempo_direcao_anterior}</Td>
									<Td alignCenter>{item.odometro}</Td>
									<Td alignCenter>{item.cidade}</Td>
									<Td alignCenter>{item.uf}</Td>
									<Td alignCenter>{item.rua}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.tempoDirecao,
	modoTela: state.tempoDirecao.modoTela,
	lista: state.tempoDirecao.lista,
    listaVeiculo: state.tempoDirecao.listaVeiculo,
	listaMotorista: state.tempoDirecao.listaMotorista,
	listaEvento: state.tempoDirecao.listaEvento
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, getLista, getListaVeiculo, getListaMotorista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TempoDirecao);
