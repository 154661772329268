import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './motoristaDocumentoForm';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, initForm, salvar, excluir, 
	getLista, getListaMotorista, getListaTipo
} from './motoristaDocumentoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import consts from '../consts';

class MotoristaDocumento extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaMotorista();
		this.props.getListaTipo();
	}
	
	componentWillUnmount() {
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
	
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>

				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<Select
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 6 6 6'
							placeholder='Selecione o motorista'
							value={this.state.id_motorista}
							onChange={value => this.setState({ ...this.state, id_motorista: value })} />

					<Select
							options={this.props.listaTipo}
							label='Documento'
							cols='12 6 6 6'
							placeholder='Selecione o documento'
							value={this.state.id_tipo}
							onChange={value => this.setState({ ...this.state, id_tipo: value })} />

					</Row>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Documento</Th>
								<Th alignCenter>Número</Th>
								<Th alignCenter>Data Documento</Th>
								<Th alignCenter>Data Vencimento</Th>
								<Th alignCenter>Data Programação</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_motorista}  ${item.tipo_documento || ''} ${item.numero || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								if (this.state.id_motorista && item.id_motorista != this.state.id_motorista) {
									return false;
								}
								if (this.state.id_tipo && item.id_tipo != this.state.id_tipo) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.nome_tipo}</Td>
									<Td alignCenter>{item.numero}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_documento)}</Td>
									<Td alignCenter 
										backgroundColor={item.data_vencimento && new Date(item.data_vencimento).getTime() < new Date(DateFormat.getDataAtual()).getTime() ? '#ff0000' : null}
										color={item.data_vencimento && new Date(item.data_vencimento).getTime() < new Date(DateFormat.getDataAtual()).getTime() ? '#ffffff' : null}>
										{DateFormat.formatarDataSqlParaTela(item.data_vencimento)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_programacao)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-file-download'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												(item.arquivos || []).map(arquivo => {
													window.open(`${consts.URL}/${arquivo.arquivo}`, '_blank');
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													data_documento: DateFormat.formatarDataSqlParaTela(item.data_documento),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													data_programacao: DateFormat.formatarDataSqlParaTela(item.data_programacao)
												});
												this.props.initForm({
													...item,
													data_documento: DateFormat.formatarDataSqlParaTela(item.data_documento),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													data_programacao: DateFormat.formatarDataSqlParaTela(item.data_programacao)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_documento: DateFormat.formatarDataSqlParaTela(item.data_documento),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													data_programacao: DateFormat.formatarDataSqlParaTela(item.data_programacao)
												});
												this.props.initForm({
													...item,
													data_documento: DateFormat.formatarDataSqlParaTela(item.data_documento),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													data_programacao: DateFormat.formatarDataSqlParaTela(item.data_programacao)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.motoristaDocumento,
	modoTela: state.motoristaDocumento.modoTela,
	lista: state.motoristaDocumento.lista,
	listaMotorista: state.motoristaDocumento.listaMotorista,
    listaTipo: state.motoristaDocumento.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, 
	getLista, getListaMotorista, getListaTipo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MotoristaDocumento);
