import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'MOTORISTA_DOCUMENTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('ocorrenciaLogisticaForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaLogistica`);
    return {
        type: 'MOTORISTA_DOCUMENTO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

        let tipo = getState().ocorrenciaLogistica.listaTipo.filter(tipo => tipo.id == registro.id_tipo)[0] || {};

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/ocorrenciaLogistica`, {
                ...registro,
                data_ocorrencia: DateFormat.formatarDataTelaParaSql(registro.data_ocorrencia),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/ocorrenciaLogistica`, {
                ...registro,
                data_ocorrencia: DateFormat.formatarDataTelaParaSql(registro.data_ocorrencia),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/ocorrenciaLogistica?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'MOTORISTA_DOCUMENTO_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaLogisticaTipo/listarSelect`);
    return {
        type: 'MOTORISTA_DOCUMENTO_TIPO_SELECT_LISTADO',
        payload: request
    };
}	