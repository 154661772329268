import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, getLista, getListaVeiculo, getListaMotorista
} from './telemetriaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';

class Telemetria extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 604800000)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
        this.props.getLista(this.state.dataInicial, this.state.dataFinal);
		this.props.getListaVeiculo();
		this.props.getListaMotorista();
	}
	
	componentWillUnmount() {
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let filtroVeiculo = this.props.listaVeiculo.filter(item => item.id == this.state.id_veiculo)[0];
		let filtroMotorista = this.props.listaMotorista.filter(item => item.id == this.state.id_motorista)[0];

		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`
					${DateFormat.formatarDataHoraSqlParaTela(item.datahora_posicao)}
					${item.nome_motorista || ''}
					${item.placa_veiculo || ''}
					${item.nome_tipo_evento || ''}
					${item.nome_evento || ''}
					${item.tempo_duracao || ''}
					${item.valor_configurado || ''}
					${item.valor_violado_freio_ar || ''}
					${item.velocidade_maxima_evento || ''}
					${item.velocidade_referencia || ''}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if (filtroVeiculo && item.placa_veiculo != filtroVeiculo.placa) {
				return false;
			}
			
			if (filtroMotorista && item.nome_motorista != filtroMotorista.valor) {
				return false;
			}

			if (this.state.id_evento && item.id_evento_rastreador != this.state.id_evento) {
				return false;
			}

			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Telemetria'}
								element={
									<Button
									text='Exportar'
									type={'primary'}
									icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora_posicao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_posicao)
								}))} name='Telemetria'>
									<ExcelColumn label='Data Hora' value='datahora_posicao' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Placa' value='placa_veiculo' />
									<ExcelColumn label='Tipo Evento' value='nome_tipo_evento' />
									<ExcelColumn label='Evento' value='nome_evento' />
									<ExcelColumn label='Duração' value='tempo_duracao' />
									<ExcelColumn label='Vlr. Conf.' value='valor_configurado' />
									<ExcelColumn label='Vlr. Freio Ar' value='valor_violado_freio_ar' />
									<ExcelColumn label='Vel. Max. Evt.' value='velocidade_maxima_evento' />
									<ExcelColumn label='Vel. Ref.' value='velocidade_referencia' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>

				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />
						
						<Select
							options={this.props.listaVeiculo}
							label='Veículo'
							cols='12 6 2 2'
							placeholder='Selecione o veículo'
							value={this.state.id_veiculo}
							onChange={value => this.setState({ ...this.state, id_veiculo: value })} />

						<Select
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 6 2 2'
							placeholder='Selecione o motorista'
							value={this.state.id_motorista}
							onChange={value => this.setState({ ...this.state, id_motorista: value })} />

						<Select
							options={this.props.listaEvento}
							label='Evento'
							cols='12 6 2 2'
							placeholder='Selecione o evento'
							value={this.state.id_evento}
							onChange={value => this.setState({ ...this.state, id_evento: value })} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista(this.state.dataInicial, this.state.dataFinal);
								}} />
						</Grid>
					</Row>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Data Hora</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Placa</Th>
								<Th alignCenter>Tipo Evento</Th>
								<Th alignCenter>Evento</Th>
								<Th alignCenter>Duração</Th>
								<Th alignCenter>Vlr. Conf.</Th>
								<Th alignCenter>Vlr. Freio Ar</Th>
								<Th alignCenter>Vel. Max. Evt.</Th>
								<Th alignCenter>Vel. Ref.</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id}>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_posicao)}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.nome_tipo_evento}</Td>
									<Td alignCenter>{item.nome_evento}</Td>
									<Td alignCenter>{item.tempo_duracao}</Td>
									<Td alignCenter>{item.valor_configurado}</Td>
									<Td alignCenter>{item.valor_violado_freio_ar}</Td>
									<Td alignCenter>{item.velocidade_maxima_evento}</Td>
									<Td alignCenter>{item.velocidade_referencia}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.telemetria,
	modoTela: state.telemetria.modoTela,
	lista: state.telemetria.lista,
    listaVeiculo: state.telemetria.listaVeiculo,
	listaMotorista: state.telemetria.listaMotorista,
	listaEvento: state.telemetria.listaEvento
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, getLista, getListaVeiculo, getListaMotorista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Telemetria);
