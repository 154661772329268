import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'MOTORISTA_DOCUMENTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('motoristaDocumentoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/motoristaDocumento`);
    return {
        type: 'MOTORISTA_DOCUMENTO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

        let tipo = getState().motoristaDocumento.listaTipo.filter(tipo => tipo.id == registro.id_tipo)[0] || {};

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/motoristaDocumento`, {
                ...registro,
                data_documento: DateFormat.formatarDataTelaParaSql(registro.data_documento),
                data_vencimento: DateFormat.formatarDataTelaParaSql(registro.data_vencimento),
                data_programacao: DateFormat.formatarDataTelaParaSql(registro.data_programacao),                
                obriga_data: tipo.obriga_data,
                obriga_vencimento: tipo.obriga_vencimento,
                obriga_programacao: tipo.obriga_programacao,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/motoristaDocumento`, {
                ...registro,
                data_documento: DateFormat.formatarDataTelaParaSql(registro.data_documento),
                data_vencimento: DateFormat.formatarDataTelaParaSql(registro.data_vencimento),
                data_programacao: DateFormat.formatarDataTelaParaSql(registro.data_programacao),                
                obriga_data: tipo.obriga_data,
                obriga_vencimento: tipo.obriga_vencimento,
                obriga_programacao: tipo.obriga_programacao,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/motoristaDocumento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'MOTORISTA_DOCUMENTO_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/motoristaDocumentoTipo/listarSelect`);
    return {
        type: 'MOTORISTA_DOCUMENTO_TIPO_SELECT_LISTADO',
        payload: request
    };
}	