import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initFormUsuario } from './clienteActions';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';

class ClienteUsuarioForm extends Component {

	state = {
		modalWhatsapp: null
	}

    componentWillMount() {
		
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>

				{this.modalWhatsapp()}

				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={'readOnly'} />

							<Field
								name='login'
								component={LabelAndInput}
								label='Login *'
								placeholder='Informe o Usuário'
								cols='12 12 12 12'
								readOnly={'readOnly'} />

							{this.props.formularioValues.senha_gerada ? (
								<Field
									name='senha_gerada'
									component={LabelAndInput}
									label='Senha Gerada'
									placeholder='Informe a Senha'
									cols='12 12 12 12'
									readOnly={'readOnly'} />
							) : null}

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>

							{this.props.formularioValues.senha_gerada &&
							<Grid cols='6 6 4 3'>
								<Button
									text='Enviar Acesso'
									type={'success'}
									icon={'fa fa-whatsapp'}
									event={async () => {
										this.setModalWhatsapp({
											...this.props.formularioValues,
											possui_celular_proprietario: this.props.formularioValues.cliente.telefone_proprietario,
											possui_celular_responsavel: this.props.formularioValues.cliente.telefone_responsavel,
											possui_celular_contato: this.props.formularioValues.cliente.celular
										});
									}} />
							</Grid>}
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (!this.props.formularioValues.senha_gerada ? (
									<Button
										text={this.props.formularioValues.id ? 'Gerar nova Senha' : 'Criar Acesso'}
										submit
										type={'success'}
										icon={'fa fa-check'} />
								) : (
									<>
										

										<Button
											text='Copiar Senha'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={async () => {
												await navigator.clipboard.writeText(this.props.formularioValues.senha_gerada);
												setSuccess('Senha Copiada')
											}} />
									</>
								))}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }

	setModalWhatsapp(modalWhatsapp) {
		this.setState({
			...this.state,
			modalWhatsapp: modalWhatsapp
		});
	}

	enviarWhatsapp(celular) {

		let mensagem = 'Olá, veja seus pedidos acessando o link abaixo: \n'
			+ 'https://valedaserra.infinitum-ti.com \n\n'
			+ `Seus dados de acesso são: \n`
			+ `*Usuário:* ${this.state.modalWhatsapp.login} \n`
			+ `*Senha:* ${this.state.modalWhatsapp.senha_gerada} \n`

		window.open(`https://api.whatsapp.com/send?phone=+55${FormatUtils.removerMascara(celular)}&text=${encodeURI(mensagem)}`, '_blank');
		
	}

	modalWhatsapp() {

		if (!this.state.modalWhatsapp) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Enviar Acesso</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setModalWhatsapp(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>

							{this.state.modalWhatsapp.possui_celular_proprietario &&
							<Row alignCenter>																
								<LabelAndInputMask
									label={'Proprietário'}
									cols='12 6 4 3'
									mask='(99) 9 9999-9999'
									value={this.state.modalWhatsapp.cliente.telefone_proprietario}
									onChange={(data) => {
										this.setModalWhatsapp({
											...this.state.modalWhatsapp,
											cliente: {
												...this.state.modalWhatsapp.cliente,
												telefone_proprietario: data.target.value
											}
										})
									}} />

								<Grid cols='6 6 4 3' style={{ marginTop: 28 }}>
									<Button
										text={'Enviar'}
										type={'success'}
										icon={'fa fa-check'}
										disabled={FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.telefone_proprietario).length < 11}
										event={() => {
											this.enviarWhatsapp(FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.telefone_proprietario));
										}} />
								</Grid>							
							</Row>}
							{this.state.modalWhatsapp.possui_celular_responsavel &&
							<Row alignCenter>																
								<LabelAndInputMask
									label={'Responsável'}
									cols='12 6 4 3'
									mask='(99) 9 9999-9999'
									value={this.state.modalWhatsapp.cliente.telefone_responsavel}
									onChange={(data) => {
										this.setModalWhatsapp({
											...this.state.modalWhatsapp,
											cliente: {
												...this.state.modalWhatsapp.cliente,
												telefone_responsavel: data.target.value
											}
										})
									}} />

								<Grid cols='6 6 4 3' style={{ marginTop: 28 }}>
									<Button
										text={'Enviar'}
										type={'success'}
										icon={'fa fa-check'}
										disabled={FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.telefone_responsavel).length < 11}
										event={() => {
											this.enviarWhatsapp(FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.telefone_responsavel));
										}} />
								</Grid>							
							</Row>}
							{this.state.modalWhatsapp.possui_celular_contato &&
							<Row alignCenter>																
								<LabelAndInputMask
									label={this.state.modalWhatsapp.cliente.nome_contato || 'Contato'}
									cols='12 6 4 3'
									mask='(99) 9 9999-9999'
									value={this.state.modalWhatsapp.cliente.celular}
									onChange={(data) => {
										this.setModalWhatsapp({
											...this.state.modalWhatsapp,
											cliente: {
												...this.state.modalWhatsapp.cliente,
												celular: data.target.value
											}
										})
									}} />

								<Grid cols='6 6 4 3' style={{ marginTop: 28 }}>
									<Button
										text={'Enviar'}
										type={'success'}
										icon={'fa fa-check'}
										disabled={FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.celular).length < 11}
										event={() => {
											this.enviarWhatsapp(FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.celular));
										}} />
								</Grid>							
							</Row>}
							<Row alignCenter>																
								<LabelAndInputMask
									label={'Outro Contato'}
									cols='12 6 4 3'
									mask='(99) 9 9999-9999'
									value={this.state.modalWhatsapp.cliente.outro_celular}
									onChange={(data) => {
										this.setModalWhatsapp({
											...this.state.modalWhatsapp,
											cliente: {
												...this.state.modalWhatsapp.cliente,
												outro_celular: data.target.value
											}
										})
									}} />

								<Grid cols='6 6 4 3' style={{ marginTop: 28 }}>
									<Button
										text={'Enviar'}
										type={'success'}
										icon={'fa fa-check'}
										disabled={FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.outro_celular).length < 11}
										event={() => {
											this.enviarWhatsapp(FormatUtils.removerMascara(this.state.modalWhatsapp.cliente.outro_celular));
										}} />
								</Grid>							
							</Row>

						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setModalWhatsapp(null)}>Fechar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

ClienteUsuarioForm = reduxForm({form: 'clienteUsuarioForm', destroyOnUnmount: false})(ClienteUsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('clienteUsuarioForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initFormUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteUsuarioForm);
