import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './veiculoForm';
import Select from 'react-select';
import SelectComponent from '../common/form/select';

import { 
	getListaDashboardKmRodado, getListaTipo, getListaUnidade, getListaPosicaoVeiculo
} from './veiculoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';

class VeiculoDashboardKmRodado extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
		this.props.getListaTipo();
		this.props.getListaUnidade();
        this.props.getListaDashboardKmRodado(this.state.dataInicial, this.state.dataFinal);
		this.props.getListaPosicaoVeiculo();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>	
				
				{this.state.info ? this.modalInfo() : null}

				<ContentCardHeader>

					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />
					
						<SelectComponent
							options={this.props.listaTipo}
							label='Tipo'
							cols='12 12 3 3'
							placeholder='Selecione o tipo'
							value={this.state.id_tipo_aux}
							onChange={value => this.setState({
								...this.state,
								id_tipo_aux: value
							})} />

						<SelectComponent
							options={this.props.listaUnidade}
							label='Unidade'
							cols='12 12 3 3'
							placeholder='Selecione a unidade'
							value={this.state.id_unidade_aux}
							onChange={value => this.setState({
								...this.state,
								id_unidade_aux: value
							})} />
							
						<Grid cols='6 6 2 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getListaDashboardKmRodado(this.state.dataInicial, this.state.dataFinal);
									this.props.getListaPosicaoVeiculo();

									this.setState({
										...this.state,
										id_unidade: this.state.id_unidade_aux,
										id_tipo: this.state.id_tipo_aux
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>

				<ContentCardBody>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter minWidth={10}>Placa</Th>
								<Th alignCenter minWidth={100}>Tipo</Th>
								<Th alignCenter minWidth={200}>Base</Th>
								<Th alignCenter minWidth={300}>Motorista</Th>
								<Th alignCenter minWidth={10}>Carreta 1</Th>
								<Th alignCenter minWidth={10}>Carreta 2</Th>
								<Th alignCenter minWidth={10}>Pos.</Th>
								<Th alignCenter minWidth={50}>Km Inicial</Th>
								<Th alignCenter minWidth={50}>Km Final</Th>
								<Th alignCenter minWidth={50}>Km Rodado</Th>
								<Th alignCenter minWidth={50}>Média</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.id_unidade && item.id_unidade != this.state.id_unidade) {
									return false;
								}
								if (this.state.id_tipo && item.id_tipo != this.state.id_tipo) {
									return false;
								}
								if (this.state.pesquisar
									&& !(`
										${item.placa || ''} ${item.nome_tipo || ''} ${item.nome_unidade || ''}
										${item.nome_motorista || ''} ${item.placa_carreta1 || ''} ${item.placa_carreta2 || ''}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => {

								let posicao = this.props.listaPosicaoVeiculo.filter(posicao => posicao.placa == item.placa)[0];

								let corMedia = '#009c46';
								let corFonteMedia = '#ffffff';
								if (parseFloat(item.media) < parseFloat(item.media_minima)) {
									corMedia = '#c20212';
									corFonteMedia = '#ffffff';
								} else {
									let mediaDiferenca = parseFloat(item.media) - parseFloat(item.media_minima);

									if ((mediaDiferenca * 100) / parseFloat(item.media_minima) < 7.5) {
										corMedia = '#c9c900';
										corFonteMedia = '#000000';
									} else if ((mediaDiferenca * 100) / parseFloat(item.media_minima) < 30.0) {
										corMedia = '#009c46';
										corFonteMedia = '#ffffff';
									} else {
										corMedia = '#c20212';
										corFonteMedia = '#ffffff';
									}
								}

								return (
									<Tr key={item.id}>
										<Td alignCenter minWidth={10}>{item.placa}</Td>
										<Td alignCenter minWidth={100}>{item.nome_tipo}</Td>
										<Td alignCenter minWidth={200}>{item.nome_unidade}</Td>
										<Td alignCenter minWidth={300}>{item.nome_motorista}</Td>
										<Td alignCenter minWidth={10}>{item.placa_carreta1}</Td>
										<Td alignCenter minWidth={10}>{item.placa_carreta2}</Td>
										<Td alignCenter minWidth={10}>{posicao && posicao.latitude && posicao.longitude ? (
											<ButtonTable
												type={'primary'}
												icon={'fas fa-map-marker-alt'}
												visible={true}
												title={posicao && posicao.telemetria ? posicao.telemetria.localizacao : null}
												event={() => {
													window.open(`https://www.google.com/maps/place/${posicao.latitude},${posicao.longitude}`, '_blank');
												}} />
											) : null}
										</Td>
										<Td alignCenter minWidth={150}>{FormatUtils.formatarValorTelaDecimal(item.odometro_inicial, 0)}</Td>
										<Td alignCenter minWidth={150}>{FormatUtils.formatarValorTelaDecimal(item.odometro_final, 0)}</Td>
										<Td alignCenter minWidth={150}>{FormatUtils.formatarValorTelaDecimal(item.km_rodado, 0)}</Td>
										<Td alignCenter minWidth={150} backgroundColor={corMedia} color={corFonteMedia} title={`Média Mínima: ${FormatUtils.formatarValorTela(item.media_minima, 2)}`}>{FormatUtils.formatarValorTelaDecimal(item.media, 2)}</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	setDialogInfo(info) {
		this.setState({
			...this.state,
			info: info
		})
	}
	
	modalInfo() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setDialogInfo(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: '80%' }}>
							<Row>
								<Grid cols='12 12 12 12'>
									{(this.state.info || '').split('\n').map(item => (
										<p key={item}>{item}</p>
									))}	
								</Grid>							
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.setDialogInfo(null);
								}}>Ok</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.veiculo,
	modoTela: state.veiculo.modoTela,
	aguardando: state.veiculo.aguardando,
	lista: state.veiculo.listaDashboardKmRodado,
	listaTipo: state.veiculo.listaTipo,
	listaUnidade: state.veiculo.listaUnidade,
	listaPosicaoVeiculo: state.veiculo.listaPosicaoVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaDashboardKmRodado, getListaTipo, getListaUnidade, getListaPosicaoVeiculo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VeiculoDashboardKmRodado);
