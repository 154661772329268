const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaMotorista: [],
    listaUnidade: [],
    listaCliente: [],
    listaEndereco: [],
    listaProduto: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'TESTE_QUALIDADE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'TESTE_QUALIDADE_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'TESTE_QUALIDADE_MOTORISTA_SELECT_LISTADO':
            return {
				...state,
				listaMotorista: action.payload.data
			};

		case 'TESTE_QUALIDADE_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

		case 'TESTE_QUALIDADE_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

		case 'TESTE_QUALIDADE_ENDERECO_SELECT_LISTADO':
            return {
				...state,
				listaEndereco: action.payload.data
			};

		case 'TESTE_QUALIDADE_PRODUTO_SELECT_LISTADO':
            return {
				...state,
				listaProduto: action.payload.data
			};

                      
        default:
            return state;
    }
}
