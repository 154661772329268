import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './estoqueManualForm';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaUnidade } from './estoqueManualActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class EstoqueManual extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaUnidade();
		this.props.getListaProduto();
	}
	
	componentWillUnmount() {
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
	
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({
										datahora: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()) 
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Data Hora</Th>
								<Th colspan={1}>Unidade</Th>
								<Th colspan={1}>Produto</Th>
								<Th colspan={1} alignCenter>Quantidade</Th>
								<Th colspan={1}>Observação (régua)</Th>
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_unidade} ${item.nome_produto || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								if (this.state.dataInicial
									&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora)) < DateFormat.formatarDataTelaParaSql(this.state.dataInicial)) {
									return false;
								}
								if (this.state.dataFinal
									&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora)) > DateFormat.formatarDataTelaParaSql(this.state.dataFinal)) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora)}</Td>
									<Td>{item.nome_unidade}</Td>
									<Td>{item.nome_produto}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)}</Td>
									<Td>{(item.observacao || '').split('\n').map(observacao => <p style={{ padding: 0, margin: 0 }}>{observacao}</p>)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora: DateFormat.formatarDataHoraSqlParaTela(item.datahora),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
													quantidade_sobre_rodas: FormatUtils.formatarValorTela(item.quantidade_sobre_rodas, 0)
												});
												this.props.initForm({
													...item,
													datahora: DateFormat.formatarDataHoraSqlParaTela(item.datahora),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
													quantidade_sobre_rodas: FormatUtils.formatarValorTela(item.quantidade_sobre_rodas, 0)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora: DateFormat.formatarDataHoraSqlParaTela(item.datahora),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
													quantidade_sobre_rodas: FormatUtils.formatarValorTela(item.quantidade_sobre_rodas, 0)
												});
												this.props.initForm({
													...item,
													datahora: DateFormat.formatarDataHoraSqlParaTela(item.datahora),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
													quantidade_sobre_rodas: FormatUtils.formatarValorTela(item.quantidade_sobre_rodas, 0)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.estoqueManual,
	modoTela: state.estoqueManual.modoTela,
	lista: state.estoqueManual.lista.filter(item => !item.petroshow)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaUnidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EstoqueManual);
