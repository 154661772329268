const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaMotorista: [],
    listaVeiculo: [],
    listaEvento: [
        { id: 1, valor: 'Jornada' },
        { id: 2, valor: 'Dirigindo' },
        { id: 3, valor: 'Pausa' },
        { id: 4, valor: 'Parada' },
        { id: 5, valor: 'Refeição' },
        { id: 6, valor: 'Esperar' },
        { id: 7, valor: 'Encerrar' },
        { id: 8, valor: 'Trocar' }
    ]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'TEMPO_DIRECAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'TEMPO_DIRECAO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'TEMPO_DIRECAO_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
            };

        case 'TEMPO_DIRECAO_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
            };
                      
        default:
            return state;
    }
}
