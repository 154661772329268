import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, importar, getListaTipo, getListaItem } from './veiculoDespesaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import LabelAndInput from '../common/form/labelAndInput';

class VeiculoDespesa extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0,
		tela: 'anual',
		ano: new Date().getFullYear(),
		semestre: `${new Date().getFullYear()}${new Date().getMonth() <= 5 ? '01' : '02'}`,
		trimestre: `${new Date().getFullYear()}${new Date().getMonth() <= 2 ? '01' : (new Date().getMonth() <= 5 ? '02' : (new Date().getMonth() <= 8 ? '03' : '04'))}`,
		mes: `${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}`
	}

	componentDidMount() {

		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importar(tabela);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelector = fileSelector;

	}

    componentWillMount() {
        this.atualizarLista(this.state.ano);
		this.props.getListaTipo();
		this.props.getListaItem();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					<Row alignCenter>
						<Grid cols='12 6 2 2'>
							<button
								type='button' 
								class={`btn btn-block btn-${this.state.tela == 'anual' ? 'dark' : 'light'} btn-flat`}
								onClick={() => {
									this.atualizarLista(this.state.ano, 'anual');
								}}>
								Anual
							</button>
						</Grid>
						<Grid cols='12 6 2 2'>
							<button
								type='button' 
								class={`btn btn-block btn-${this.state.tela == 'semestral' ? 'dark' : 'light'} btn-flat`}
								onClick={() => {
									this.atualizarLista(this.state.semestre, 'semestral');
								}}>
								Semestral
							</button>
						</Grid>
						<Grid cols='12 6 2 2'>
							<button
								type='button' 
								class={`btn btn-block btn-${this.state.tela == 'trimestral' ? 'dark' : 'light'} btn-flat`}
								onClick={() => {
									this.atualizarLista(this.state.trimestre, 'trimestral');
								}}>
								Trimestral
							</button>
						</Grid>
						<Grid cols='12 6 2 2'>
							<button
								type='button' 
								class={`btn btn-block btn-${this.state.tela == 'mensal' ? 'dark' : 'light'} btn-flat`}
								onClick={() => {
									this.atualizarLista(this.state.mes, 'mensal');
								}}>
								Mensal
							</button>
						</Grid>
						<Grid cols='12 6 2 2'>
							<button
								type='button' 
								class={`btn btn-block btn-${this.state.tela == 'lista' ? 'dark ' : 'light'} btn-flat`}
								onClick={() => {
									this.atualizarLista(this.state.lista, 'lista');
								}}>
								Lista
							</button>
						</Grid>
					</Row>

					{this.lista()}

                </Content>
            </div>
        )
    }

	atualizarLista(value, tela) {

		let dataInicial = null;
		let dataFinal = null;

		if (tela) {
			this.setState({ ...this.state, tela: tela });									
		} else {
			tela = this.state.tela;
		}

		if (tela == 'anual') {
			dataInicial = (this.props.listaAno.filter(item => item. id == value)[0] || {}).dataInicial;
			dataFinal = (this.props.listaAno.filter(item => item. id == value)[0] || {}).dataFinal;
		}
		if (tela == 'semestral') {
			dataInicial = (this.props.listaSemestre.filter(item => item. id == value)[0] || {}).dataInicial;
			dataFinal = (this.props.listaSemestre.filter(item => item. id == value)[0] || {}).dataFinal;
		}
		if (tela == 'trimestral') {
			dataInicial = (this.props.listaTrimestre.filter(item => item. id == value)[0] || {}).dataInicial;
			dataFinal = (this.props.listaTrimestre.filter(item => item. id == value)[0] || {}).dataFinal;
		}
		if (tela == 'mensal') {
			dataInicial = (this.props.listaMes.filter(item => item. id == value)[0] || {}).dataInicial;
			dataFinal = (this.props.listaMes.filter(item => item. id == value)[0] || {}).dataFinal;
		}
		if (tela == 'lista' ) {
			dataInicial = '2000/01/01';
			dataFinal = '3000/12/31';
		}

		this.props.getLista(dataInicial, dataFinal);
	}

	lista() {

		let lista = (this.props.lista).filter(item => {
			if (this.state.placas && this.state.placas.split('+').filter(placa => (placa || '').trim()).length
				&& !this.state.placas.split('+').filter(placa => (placa || '').trim()).includes(item.placa_veiculo)) {
				return false;
			}
			if (this.state.id_tipo && item.id_tipo != this.state.id_tipo) {
				return false;
			}
			if (this.state.id_item && item.id_item != this.state.id_item) {
				return false;
			}
			return true;
		});

		if (this.state.tela != 'lista') {
			let listaAux = [];
			lista.forEach(item => {
				if (!listaAux.filter(itemAux => itemAux.placa_veiculo == item.placa_veiculo)[0]) {
					listaAux.push({
						placa_veiculo: item.placa_veiculo,
						quantidade: parseFloat(item.quantidade),
						valor_total: parseFloat(item.valor_total)
					});
				} else {
					listaAux = listaAux.map(itemAux => {
						if (itemAux.placa_veiculo == item.placa_veiculo) {
							return {
								...itemAux,
								quantidade: itemAux.quantidade + parseFloat(item.quantidade),
								valor_total: itemAux.valor_total + parseFloat(item.valor_total)
							};
						} else {
							return itemAux;
						}
					});
				}
			});

			lista = listaAux.map(item => ({ ...item }));
		}

		let quantidadeTotal = 0;
		let  valorTotal = 0;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						{(true || this.props.listaTipo.length > 0 && this.props.listaItem.length > 0) &&
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									// if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
										 this.fileSelector.click()
									// }
								}} />
						</Grid>}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					
					<Row alignCenter>
						{this.state.tela == 'anual' &&
						<Select
							options={this.props.listaAno}
							label='Ano'
							cols='12 4 2 2'
							placeholder='Selecione'
							value={this.state.ano}
							onChange={value => {
								this.setState({ ...this.state, ano: value });
								this.atualizarLista(value);
							}} />}
					
						{this.state.tela == 'semestral' &&
						<Select
							options={this.props.listaSemestre}
							label='Semestre'
							cols='12 4 2 2'
							placeholder='Selecione'
							value={this.state.semestre}
							onChange={value => {
								this.setState({ ...this.state, semestre: value });
								this.atualizarLista(value);
							}} />}
					
						{this.state.tela == 'trimestral' &&
						<Select
							options={this.props.listaTrimestre}
							label='Trimestre'
							cols='12 4 2 2'
							placeholder='Selecione'
							value={this.state.trimestre}
							onChange={value => {
								this.setState({ ...this.state, trimestre: value });
								this.atualizarLista(value);
							}} />}
					
						{this.state.tela == 'mensal' &&
						<Select
							options={this.props.listaMes}
							label='Mês'
							cols='12 4 2 2'
							placeholder='Selecione'
							value={this.state.mes}
							onChange={value => {
								this.setState({ ...this.state, mes: value });
								this.atualizarLista(value);
							}} />}

						<Select
							options={this.props.listaTipo}
							label='Tipo'
							cols='12 6 3 3'
							placeholder='Selecione'
							value={this.state.id_tipo}
							onChange={value => {
								this.setState({ ...this.state, id_tipo: value });
							}} />
						
						<Select
							options={this.props.listaItem}
							label='Item'
							cols='12 6 3 3'
							placeholder='Selecione'
							value={this.state.id_item}
							onChange={value => {
								this.setState({ ...this.state, id_item: value });
							}} />

						<LabelAndInput
							label='Placas'
							cols='12 6 4 4'
							placeholder='Múltiplas placas separando com +'
							value={this.state.placas}
							onChange={data => {
								this.setState({ ...this.state, placas: data.target.value });
							}} />
					</Row>

					{this.state.tela == 'lista' ? (
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th alignCenter>Veículo</Th>
									<Th alignCenter>Tipo</Th>
									<Th alignCenter>Item</Th>
									<Th alignCenter>Quantidade</Th>
									<Th alignCenter>Valor Unitário</Th>
									<Th alignCenter>Valor Total</Th>
								</Tr>
							</THead>
							<TBody>
								{lista.map(item => {
									quantidadeTotal += parseFloat(item.quantidade);
									valorTotal += parseFloat(item.valor_total);
								
									return (
										<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)}</Td>
											<Td alignCenter>{item.placa_veiculo}</Td>
											<Td alignCenter>{item.nome_tipo}</Td>
											<Td alignCenter>{item.nome_item}</Td>
											<Td alignRight>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 2)}</Td>
											<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.valor_unitario, 2)}</Td>
											<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.valor_total, 2)}</Td>
										</Tr>
									);
								})}
								<Tr>
									<Td bold>Total</Td>
									<Td alignCenter bold></Td>
									<Td alignCenter bold></Td>
									<Td alignCenter bold></Td>
									<Td alignRight bold>{FormatUtils.formatarValorTelaDecimal(quantidadeTotal, 2)}</Td>
									<Td alignRight bold></Td>
									<Td alignRight bold>R$ {FormatUtils.formatarValorTelaDecimal(valorTotal, 2)}</Td>
								</Tr>
							</TBody>
						</Table>
					) : (
						<Table responsive>
							<THead>
								<Tr>
									<Th>Veículo</Th>
									<Th alignRight>Quantidade</Th>
									<Th alignRight>Valor Total</Th>
								</Tr>
							</THead>
							<TBody>
								{lista.map(item => {
									quantidadeTotal += parseFloat(item.quantidade);
									valorTotal += parseFloat(item.valor_total);
									return (
										<Tr key={item.placa} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											<Td>{item.placa_veiculo}</Td>
											<Td alignRight>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 2)}</Td>
											<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.valor_total, 2)}</Td>
										</Tr>
									);
								})}
								<Tr>
									<Td bold>Total</Td>
									<Td alignRight bold>{FormatUtils.formatarValorTelaDecimal(quantidadeTotal, 2)}</Td>
									<Td alignRight bold>R$ {FormatUtils.formatarValorTelaDecimal(valorTotal, 2)}</Td>
								</Tr>
							</TBody>
						</Table>
					)}
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.veiculoDespesa.modoTela,
	aguardando: state.veiculoDespesa.aguardando,
	listaTipo: state.veiculoDespesa.listaTipo,
	listaItem: state.veiculoDespesa.listaItem,
	lista: state.veiculoDespesa.lista,
	listaAno: state.veiculoDespesa.listaAno,
	listaSemestre: state.veiculoDespesa.listaSemestre,
	listaTrimestre: state.veiculoDespesa.listaTrimestre,
	listaMes: state.veiculoDespesa.listaMes
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, importar, getListaTipo, getListaItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VeiculoDespesa);
