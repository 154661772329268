import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'TESTE_QUALIDADE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('testeQualidadeForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/testeQualidade`);
    return {
        type: 'TESTE_QUALIDADE_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/testeQualidade`, {
                ...registro,
                datahora_realizacao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_realizacao),
                id_tecnico: getState().auth.usuario.id,
				temperatura: FormatUtils.formatarValorBanco(registro.temperatura, 4),
				massa: FormatUtils.formatarValorBanco(registro.massa, 4),
				densidade: FormatUtils.formatarValorBanco(registro.densidade, 4),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/testeQualidade`, {
                ...registro,
                datahora_realizacao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_realizacao),
                id_tecnico: getState().auth.usuario.id,
				temperatura: FormatUtils.formatarValorBanco(registro.temperatura, 4),
				massa: FormatUtils.formatarValorBanco(registro.massa, 4),
				densidade: FormatUtils.formatarValorBanco(registro.densidade, 4),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/testeQualidade?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'TESTE_QUALIDADE_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'TESTE_QUALIDADE_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'TESTE_QUALIDADE_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEndereco() {
    const request = axios.get(`${consts.API_URL}/endereco/listarSelect`);
    return {
        type: 'TESTE_QUALIDADE_ENDERECO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect?todos=true`);
    return {
        type: 'TESTE_QUALIDADE_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}