import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize, Form } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'META_VENDA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('metaVendaForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'META_VENDA_LISTADO',
			payload: { data: [] }
		});

    	axios.get(`${consts.API_URL}/metaVenda`).then(result => {
			dispatch({
				type: 'META_VENDA_LISTADO',
				payload: result
			});
		});
	}
}

export function getRegistroDashboard() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'META_VENDA_DASHBOARD_LISTADO',
			payload: null
		});

    	axios.get(`${consts.API_URL}/metaVenda`).then(result => {
			dispatch({
				type: 'META_VENDA_DASHBOARD_LISTADO',
				payload: result && result.data && result.data[0] ? {
					...result.data[0],
					peso_volume: FormatUtils.formatarValorTela(result.data[0].peso_volume || 0, 4),
					percentual_volume: FormatUtils.formatarValorTela(result.data[0].percentual_volume || 0, 4),
					peso_a1: FormatUtils.formatarValorTela(result.data[0].peso_a1 || 0, 4),
					peso_a2: FormatUtils.formatarValorTela(result.data[0].peso_a2 || 0, 4),
					peso_a3: FormatUtils.formatarValorTela(result.data[0].peso_a3 || 0, 4),
					peso_a4: FormatUtils.formatarValorTela(result.data[0].peso_a4 || 0, 4),
					meses: (result.data[0].meses || []).map(mes => ({
						...mes,
						volume_base: FormatUtils.formatarValorTela(mes.volume_base || 0, 4),
						volume_total: FormatUtils.formatarValorTela(mes.volume_total || 0, 4),
						margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
						margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
						inadimplencia_global: FormatUtils.formatarValorTela(mes.inadimplencia_global || 0, 4),
						prazo_global: FormatUtils.formatarValorTela(mes.prazo_global || 0, 4),
						volume_a1: FormatUtils.formatarValorTela(mes.volume_a1 || 0, 4),
						margem_a1: FormatUtils.formatarValorTela(mes.margem_a1 || 0, 4),
						inadimplencia_a1: FormatUtils.formatarValorTela(mes.inadimplencia_a1 || 0, 4),
						prazo_a1: FormatUtils.formatarValorTela(mes.prazo_a1 || 0, 4),
						volume_a2: FormatUtils.formatarValorTela(mes.volume_a2 || 0, 4),
						margem_a2: FormatUtils.formatarValorTela(mes.margem_a2 || 0, 4),
						inadimplencia_a2: FormatUtils.formatarValorTela(mes.inadimplencia_a2 || 0, 4),
						prazo_a2: FormatUtils.formatarValorTela(mes.prazo_a2 || 0, 4),
						volume_a3: FormatUtils.formatarValorTela(mes.volume_a3 || 0, 4),
						margem_a3: FormatUtils.formatarValorTela(mes.margem_a3 || 0, 4),
						inadimplencia_a3: FormatUtils.formatarValorTela(mes.inadimplencia_a3 || 0, 4),
						prazo_a3:FormatUtils.formatarValorTela(mes.prazo_a3 || 0, 4),
						volume_a4: FormatUtils.formatarValorTela(mes.volume_a4 || 0, 4),
						margem_a4: FormatUtils.formatarValorTela(mes.margem_a4 || 0, 4),
						inadimplencia_a4: FormatUtils.formatarValorTela(mes.inadimplencia_a4 || 0, 4),
						prazo_a4:FormatUtils.formatarValorTela(mes.prazo_a4 || 0, 4),
						realizado_volume_a1: FormatUtils.formatarValorTela(mes.realizado_volume_a1 || 0, 4),
						realizado_margem_a1: FormatUtils.formatarValorTela(mes.realizado_margem_a1 || 0, 4),
						realizado_inadimplencia_a1: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a1 || 0, 4),
						realizado_prazo_a1: FormatUtils.formatarValorTela(mes.realizado_prazo_a1 || 0, 4),
						realizado_volume_a2: FormatUtils.formatarValorTela(mes.realizado_volume_a2 || 0, 4),
						realizado_margem_a2: FormatUtils.formatarValorTela(mes.realizado_margem_a2 || 0, 4),
						realizado_inadimplencia_a2: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a2 || 0, 4),
						realizado_prazo_a2: FormatUtils.formatarValorTela(mes.realizado_prazo_a2 || 0, 4),
						realizado_volume_a3: FormatUtils.formatarValorTela(mes.realizado_volume_a3 || 0, 4),
						realizado_margem_a3: FormatUtils.formatarValorTela(mes.realizado_margem_a3 || 0, 4),
						realizado_inadimplencia_a3: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a3 || 0, 4),
						realizado_prazo_a3: FormatUtils.formatarValorTela(mes.realizado_prazo_a3 || 0, 4),
						realizado_volume_a4: FormatUtils.formatarValorTela(mes.realizado_volume_a4 || 0, 4),
						realizado_margem_a4: FormatUtils.formatarValorTela(mes.realizado_margem_a4 || 0, 4),
						realizado_inadimplencia_a4: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a4 || 0, 4),
						realizado_prazo_a4: FormatUtils.formatarValorTela(mes.realizado_prazo_a4 || 0, 4)
					}))
				} : null
			});
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/metaVenda`, {
				...registro,
				peso_volume: FormatUtils.formatarValorBanco(registro.peso_volume || 0, 4),
				percentual_volume: FormatUtils.formatarValorBanco(registro.percentual_volume || 0, 4),
				peso_a1: FormatUtils.formatarValorBanco(registro.peso_a1 || 0, 4),
				peso_a2: FormatUtils.formatarValorBanco(registro.peso_a2 || 0, 4),
				peso_a3: FormatUtils.formatarValorBanco(registro.peso_a3 || 0, 4),
				peso_a4: FormatUtils.formatarValorBanco(registro.peso_a4 || 0, 4),
				meses: (registro.meses || []).map(mes => ({
					...mes,
					volume_base: FormatUtils.formatarValorBanco(mes.volume_base || 0, 4),
					volume_total: FormatUtils.formatarValorBanco(mes.volume_total || 0, 4),
					margem_global: FormatUtils.formatarValorBanco(mes.margem_global || 0, 4),
					margem_global: FormatUtils.formatarValorBanco(mes.margem_global || 0, 4),
					inadimplencia_global: FormatUtils.formatarValorBanco(mes.inadimplencia_global || 0, 4),
					prazo_global: FormatUtils.formatarValorBanco(mes.prazo_global || 0, 4),
					volume_a1: FormatUtils.formatarValorBanco(mes.volume_a1 || 0, 4),
					margem_a1: FormatUtils.formatarValorBanco(mes.margem_a1 || 0, 4),
					inadimplencia_a1: FormatUtils.formatarValorBanco(mes.inadimplencia_a1 || 0, 4),
					prazo_a1: FormatUtils.formatarValorBanco(mes.prazo_a1 || 0, 4),
					volume_a2: FormatUtils.formatarValorBanco(mes.volume_a2 || 0, 4),
					margem_a2: FormatUtils.formatarValorBanco(mes.margem_a2 || 0, 4),
					inadimplencia_a2: FormatUtils.formatarValorBanco(mes.inadimplencia_a2 || 0, 4),
					prazo_a2: FormatUtils.formatarValorBanco(mes.prazo_a2 || 0, 4),
					volume_a3: FormatUtils.formatarValorBanco(mes.volume_a3 || 0, 4),
					margem_a3: FormatUtils.formatarValorBanco(mes.margem_a3 || 0, 4),
					inadimplencia_a3: FormatUtils.formatarValorBanco(mes.inadimplencia_a3 || 0, 4),
					prazo_a3:FormatUtils.formatarValorBanco(mes.prazo_a3 || 0, 4),
					volume_a4: FormatUtils.formatarValorBanco(mes.volume_a4 || 0, 4),
					margem_a4: FormatUtils.formatarValorBanco(mes.margem_a4 || 0, 4),
					inadimplencia_a4: FormatUtils.formatarValorBanco(mes.inadimplencia_a4 || 0, 4),
					prazo_a4:FormatUtils.formatarValorBanco(mes.prazo_a4 || 0, 4),
					ebitda: FormatUtils.formatarValorBanco(mes.ebitda || 0, 4),
					faturamento: FormatUtils.formatarValorBanco(mes.faturamento || 0, 4)
				})),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/metaVenda`, {
				...registro,
				peso_volume: FormatUtils.formatarValorBanco(registro.peso_volume || 0, 4),
				percentual_volume: FormatUtils.formatarValorBanco(registro.percentual_volume || 0, 4),
				peso_a1: FormatUtils.formatarValorBanco(registro.peso_a1 || 0, 4),
				peso_a2: FormatUtils.formatarValorBanco(registro.peso_a2 || 0, 4),
				peso_a3: FormatUtils.formatarValorBanco(registro.peso_a3 || 0, 4),
				peso_a4: FormatUtils.formatarValorBanco(registro.peso_a4 || 0, 4),
				meses: (registro.meses || []).map(mes => ({
					...mes,
					volume_base: FormatUtils.formatarValorBanco(mes.volume_base || 0, 4),
					volume_total: FormatUtils.formatarValorBanco(mes.volume_total || 0, 4),
					margem_global: FormatUtils.formatarValorBanco(mes.margem_global || 0, 4),
					margem_global: FormatUtils.formatarValorBanco(mes.margem_global || 0, 4),
					inadimplencia_global: FormatUtils.formatarValorBanco(mes.inadimplencia_global || 0, 4),
					prazo_global: FormatUtils.formatarValorBanco(mes.prazo_global || 0, 4),
					volume_a1: FormatUtils.formatarValorBanco(mes.volume_a1 || 0, 4),
					margem_a1: FormatUtils.formatarValorBanco(mes.margem_a1 || 0, 4),
					inadimplencia_a1: FormatUtils.formatarValorBanco(mes.inadimplencia_a1 || 0, 4),
					prazo_a1: FormatUtils.formatarValorBanco(mes.prazo_a1 || 0, 4),
					volume_a2: FormatUtils.formatarValorBanco(mes.volume_a2 || 0, 4),
					margem_a2: FormatUtils.formatarValorBanco(mes.margem_a2 || 0, 4),
					inadimplencia_a2: FormatUtils.formatarValorBanco(mes.inadimplencia_a2 || 0, 4),
					prazo_a2: FormatUtils.formatarValorBanco(mes.prazo_a2 || 0, 4),
					volume_a3: FormatUtils.formatarValorBanco(mes.volume_a3 || 0, 4),
					margem_a3: FormatUtils.formatarValorBanco(mes.margem_a3 || 0, 4),
					inadimplencia_a3: FormatUtils.formatarValorBanco(mes.inadimplencia_a3 || 0, 4),
					prazo_a3:FormatUtils.formatarValorBanco(mes.prazo_a3 || 0, 4),
					volume_a4: FormatUtils.formatarValorBanco(mes.volume_a4 || 0, 4),
					margem_a4: FormatUtils.formatarValorBanco(mes.margem_a4 || 0, 4),
					inadimplencia_a4: FormatUtils.formatarValorBanco(mes.inadimplencia_a4 || 0, 4),
					prazo_a4:FormatUtils.formatarValorBanco(mes.prazo_a4 || 0, 4),
					ebitda: FormatUtils.formatarValorBanco(mes.ebitda || 0, 4),
					faturamento: FormatUtils.formatarValorBanco(mes.faturamento || 0, 4)
				}))
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente`);
    return {
        type: 'META_VENDA_CLIENTE_LISTADO',
        payload: request
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/metaVenda?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function importarVenda(tabela, registro) {
    return async (dispatch, getState) => {

		let listaVenda = [];
		let vendedores = [
			{ id: 'A1', nomes: [ 'FABIO HENRIQUE ARAUJO DE FREITAS' ] },
			{ id: 'A2', nomes: [ 'LUANNA CONCEICAO MARTINS SILVA', 'LUIZ GONZAGA SOARES DE SOUSA JUNIOR' ] },
			{ id: 'A3', nomes: [ 'CAMILA SARAIVA' ] },
			{ id: 'A4', nomes: [  ] }
		];
		let listaAgrupador = [];

		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];

			// let data = `${String(item[4] || '').trim().split('/')[2]}/${String(item[4] || '').trim().split('/')[1]}/${String(item[4] || '').trim().split('/')[0]}`;
			let dataHora = new Date(String(item[4]));
			dataHora.setHours(dataHora.getHours() + 3);
			let data = dataHora ? `${dataHora.getFullYear()}/${String(dataHora.getMonth() + 1).padStart(2, '0')}/${String(dataHora.getDate()).padStart(2, '0')}` : null;

			let venda = {
				dataEmissao: data,
				dataString: item[4],				
				codigoOperacao: String(item[6] || '').trim(),
				ano: new Date(data).getFullYear(),//String(item[7] || '').trim(),
				mes: new Date(data).getMonth() + 1,//String(item[8] || '').trim(),
				vendedor: String(item[10] || '').trim(),
				produto: String(item[15] || '').trim(),
				quantidade: parseFloat(String(item[16] || '').trim().replace(',', '.') || 0),
				valor: parseFloat(String(item[17] || '').trim().replace(',', '.') || 0),
				custo: parseFloat(String(item[26] || '').trim().replace(',', '.') || 0),
				prazo: parseFloat(String(item[34] || '').trim().replace(',', '.') || 0),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			};

			// if (data == '2024/02/06') {
			// console.log(String(item[4]));
			// console.log(data);
			// }
			
			if (venda.codigoOperacao.includes('VENDA DE COMBUSTIVEIS') 
				&& venda.ano == registro.exercicio
				&& vendedores.filter(vendedor => vendedor.nomes.includes(venda.vendedor)).length) {
				listaVenda.push(venda);
				
				const idx = `${vendedores.filter(vendedor => vendedor.nomes.includes(venda.vendedor))[0].id}-${venda.ano}-${venda.mes}`;
				if (!listaAgrupador.filter(item => item.idx == idx).length) {
					listaAgrupador.push({
						idx: idx,
						vendedor: vendedores.filter(vendedor => vendedor.nomes.includes(venda.vendedor))[0].id,
						vendedorNomes: vendedores.filter(vendedor => vendedor.nomes.includes(venda.vendedor))[0].nomes,
						ano: venda.ano,
						mes: venda.mes
					});
				}
			}
			
		}

		const getSemana = (data) => {
			let d = new Date(data);
			let date = d.getDate();
			let day = d.getDay();

			return Math.ceil((date - 1 - day) / 7) < 0 ? 0 : Math.ceil((date - 1 - day) / 7);
		};
		listaAgrupador.forEach(agrupador => {
			let volume = 0;
			let semanas = Array.from(Array(5).keys()).map((item, i) => ({ semana: i, dias: Array.from(Array(7).keys()).map((dia, i) => ({ dia: i, volume: 0 })) }));
			let valor = 0;
			let custo = 0;
			let prazo = 0;
			listaVenda.filter(venda => agrupador.vendedorNomes.includes(venda.vendedor) 
				&& venda.ano == agrupador.ano 
				&& parseInt(venda.mes) == parseInt(agrupador.mes)
			).forEach(venda => {
				volume += venda.quantidade;
				
				semanas = semanas.map(semana => ({
					...semana,
					dias: semana.dias.map(dia => {
						if (semana.semana == getSemana(venda.dataEmissao) && dia.dia == new Date(venda.dataEmissao).getDay()) {
							return {
								...dia,
								volume: dia.volume + parseInt(venda.quantidade)
							};
						} else {
							return dia;
						}
					})
				}));
				
				valor += venda.valor;
				custo += venda.custo;
				prazo += venda.prazo * venda.quantidade;
			});
			
			registro = {
				...registro,
				meses: registro.meses.map(mes => {
					
					if (parseInt(mes.mes) == parseInt(agrupador.mes)) {
						if (agrupador.vendedor == 'A1') {
							return {
								...mes,
								realizado_volume_a1: volume,
								realizado_volume_semana_a1: semanas,
								realizado_margem_a1: volume > 0 ? ((valor - custo) / volume).toFixed(3) : 0,
								// realizado_inadimplencia_a1,
								realizado_prazo_a1: volume > 0 ? (prazo / volume).toFixed(1) : 0,
								realizado_valor_a1: valor
							};
						} else if (agrupador.vendedor == 'A2') {							
							return {
								...mes,
								realizado_volume_a2: volume,
								realizado_volume_semana_a2: semanas,
								realizado_margem_a2: volume > 0 ? ((valor - custo) / volume).toFixed(3) : 0,
								// realizado_inadimplencia_a2,
								realizado_prazo_a2: volume > 0 ? (prazo / volume).toFixed(1) : 0,
								realizado_valor_a2: valor
							};
						} else if (agrupador.vendedor == 'A3') {
							return {
								...mes,
								realizado_volume_a3: volume,
								realizado_volume_semana_a3: semanas,
								realizado_margem_a3: volume > 0 ? ((valor - custo) / volume).toFixed(3) : 0,
								// realizado_inadimplencia_a3,
								realizado_prazo_a3: volume > 0 ? (prazo / volume).toFixed(1) : 0,
								realizado_valor_a3: valor
							};
						} else if (agrupador.vendedor == 'A4') {
							return {
								...mes,
								realizado_volume_a4: volume,
								realizado_volume_semana_a4: semanas,
								realizado_margem_a4: volume > 0 ? ((valor - custo) / volume).toFixed(3) : 0,
								// realizado_inadimplencia_a4,
								realizado_prazo_a4: volume > 0 ? (prazo / volume).toFixed(1) : 0,
								realizado_valor_a4: valor
							};
						}
					} else {
						return mes;
					}
				})
			}

		});

		await axios.put(`${consts.API_URL}/metaVenda/realizado`, {
			...registro
		}).then(resp => {
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setModoTela('lista'));
    };
}

export function importarInadimplencia(tabela, registro) {
    return async (dispatch, getState) => {

		let vendedores = [
			{ id: 'A1', nomes: [ 'FABIO HENRIQUE ARAUJO DE FREITAS' ], codigos: [ '8485' ] },
			{ id: 'A2', nomes: [ 'LUANNA CONCEICAO MARTINS SILVA', 'LUIZ GONZAGA SOARES DE SOUSA JUNIOR' ], codigos: [ '19741', '22932' ] },
			{ id: 'A3', nomes: [ 'CAMILA SARAIVA' ], codigos: [ '19592' ] },
			{ id: 'A4', nomes: [ 'WANDERSON GEOVANE CAMARGO' ], codigos: [ '3764' ] }
		];
		let listaAgrupador = [];

		let codCliente = null;
		// console.log(tabela);
		
		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];			

			if (!codCliente || parseInt(String(item[0] || '').split('-')[0].trim())) {
				codCliente = parseInt(String(item[0] || '').split('-')[0].trim());
			}
			if (codCliente && item[2] && item[4]) {
			
				const vencimento = new Date(`${String(item[2] || '').trim().split('/')[2]}/${String(item[2] || '').trim().split('/')[1]}/${String(item[2] || '').trim().split('/')[0]}`);
				// console.log(String(item[2]));
				const valor = parseFloat(String(item[4] || '').trim().replace(',', '.') || 0);

				// console.log(item[0]);
				// console.log((new Date().getTime() - vencimento.getTime()) / 1000 / 60 / 60 / 24);				
				
				// 30 dias
				// console.log(`${vencimento && (new Date().getTime() - vencimento.getTime()) > 2592000000 && valor > 0} - ${vencimento} - ${new Date().getTime() - vencimento.getTime()}`);
				if (vencimento && (new Date().getTime() - vencimento.getTime()) > 2592000000 && valor > 0) {

					
					const cliente = getState().metaVenda.listaCliente.filter(cli => cli.id_petroshow == codCliente)[0];					

					if (cliente) {
						const vendedor = vendedores.filter(vend => vend.codigos.filter(codVend => (cliente.vendedores || []).includes(codVend)).length)[0];

						// console.log(vendedor);
						

						if (vendedor) {

							// console.log('Vencido');
							
							if (!listaAgrupador.filter(agrupador => agrupador.idVendedor == vendedor.id).length) {
								listaAgrupador.push({
									idVendedor: vendedor.id,
									valor: valor,
									clientes: [{ id: cliente.id, id_petroshow: cliente.id_petroshow, razao_social: cliente.razao_social, nome_fantasia: cliente.nome_fantasia, valor: valor }]
								});
							} else {
								listaAgrupador = listaAgrupador.map(agrupador => {
									if (agrupador.idVendedor == vendedor.id) {
										let clientes = (agrupador.clientes || []).map(cli => cli);
										clientes.push({ id: cliente.id, id_petroshow: cliente.id_petroshow, razao_social: cliente.razao_social, nome_fantasia: cliente.nome_fantasia, valor: valor });
										return {
											...agrupador,
											valor: agrupador.valor + valor,
											clientes: clientes
										}
									} else {
										return agrupador;
									}
								});
							}
						}
					}
				}
			}			
		}

		registro = {
			...registro,
			meses: registro.meses.map(mes => {
				//Mes Atual
				if (parseInt(mes.mes) == (new Date().getMonth() + 1)) {
					return {
						...mes,
						realizado_inadimplencia_a1: 0,
						realizado_inadimplencia_a2: 0,
						realizado_inadimplencia_a3: 0,
						realizado_inadimplencia_a4: 0,
						realizado_inadimplencia_valor_a1: 0,
						realizado_inadimplencia_valor_a2: 0,
						realizado_inadimplencia_valor_a3: 0,
						realizado_inadimplencia_valor_a4: 0,
						realizado_inadimplencia_clientes_a1: [],
						realizado_inadimplencia_clientes_a2: [],
						realizado_inadimplencia_clientes_a3: [],
						realizado_inadimplencia_clientes_a4: []
					}
				} else {
					return mes;
				}
			})
		}

		listaAgrupador.forEach(agrupador => {
			
			registro = {
				...registro,
				meses: registro.meses.map(mes => {
					//Mes Atual
					if (parseInt(mes.mes) == (new Date().getMonth() + 1)) {
						let indice = 0;
						switch (new Date().getMonth() + 1) {
							case 1:
								indice = 26;
								break;
							case 2:
								indice = 24;
								break;
							case 3:
								indice = 27;
								break;
							case 4:
								indice = 26;
								break;
							case 5:
								indice = 26;
								break;
							case 6:
								indice = 26;
								break;
							case 7:
								indice = 26;
								break;
							case 8:
								indice = 27;
								break;
							case 9:
								indice = 26;
								break;
							case 10:
								indice = 26;
								break;
							case 11:
								indice = 26;
								break;
							case 12:
								indice = 27;
								break;
						}

						if (agrupador.idVendedor == 'A1') {
							// console.log(`A1: ${agrupador.valor} - ${indice / new Date().getDate()} - ${mes.realizado_valor_a1}`);
							return {
								...mes,
								realizado_inadimplencia_a1: (mes.realizado_valor_a1 > 0 ? (agrupador.valor / (mes.realizado_valor_a1 * (indice / new Date().getDate()))) * 100 : 0).toFixed(3),
								realizado_inadimplencia_valor_a1: agrupador.valor || 0,
								realizado_inadimplencia_clientes_a1: agrupador.clientes || []
							};
						} else if (agrupador.idVendedor == 'A2') {
							console.log(`A2: ${agrupador.valor} - ${indice / new Date().getDate()} - ${mes.realizado_valor_a2}`);							
							return {
								...mes,
								realizado_inadimplencia_a2: (mes.realizado_valor_a2 > 0 ? (agrupador.valor / (mes.realizado_valor_a2 * (indice / new Date().getDate()))) * 100 : 0).toFixed(3),
								realizado_inadimplencia_valor_a2: agrupador.valor || 0,
								realizado_inadimplencia_clientes_a2: agrupador.clientes || []
							};
						} else if (agrupador.idVendedor == 'A3') {
							console.log(`A3: ((${agrupador.valor} / (${mes.realizado_valor_a3} * (${indice} / ${new Date().getDate()}))) * ${100}`);
							return {
								...mes,
								realizado_inadimplencia_a3: (mes.realizado_valor_a3 > 0 ? (agrupador.valor / (mes.realizado_valor_a3 * (indice / new Date().getDate()))) * 100 : 0).toFixed(3),
								realizado_inadimplencia_valor_a3: agrupador.valor || 0,
								realizado_inadimplencia_clientes_a3: agrupador.clientes || []
							};
						} else if (agrupador.idVendedor == 'A4') {
							// console.log(`A4: ${agrupador.valor} - ${indice / new Date().getDate()} - ${mes.realizado_valor_a4}`);
							return {
								...mes,
								realizado_inadimplencia_a4: (mes.realizado_valor_a4 > 0 ? (agrupador.valor / (mes.realizado_valor_a4 * (indice / new Date().getDate()))) * 100 : 0).toFixed(3),
								realizado_inadimplencia_valor_a4: agrupador.valor || 0,
								realizado_inadimplencia_clientes_a4: agrupador.clientes || []
							};
						}
						// console.log(mes);
					} else {
						return mes;
					}
				})
			}

		});
		
		await axios.put(`${consts.API_URL}/metaVenda/realizado`, {
			...registro
		}).then(resp => {
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setModoTela('lista'));
    };
}

export function imprimirVendedor(registro) {
	return (dispatch, getState) => {
		
		axios.post(`${consts.API_URL}/metaVenda/imprimirVendedor`, {
            ...registro,
            datahora_emissao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
        })
		.then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});
	}
}

export function imprimirGerencia(registro) {
	return (dispatch, getState) => {
		
		axios.post(`${consts.API_URL}/metaVenda/imprimirGerencia`, {
            ...registro,
            datahora_emissao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
        })
		.then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});
	}
}