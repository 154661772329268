import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PROSPECCAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setModoTelaCliente(modo, registro = {}) {
    return {
        type: 'CLIENTE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('prospeccaoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/prospeccao`);
    return {
        type: 'PROSPECCAO_LISTADO',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente?agenda=true`);
    return {
        type: 'CLIENTE_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/prospeccao`, {
				...registro,
				datahora_agenda: DateFormat.formatarDataTelaParaSql(registro.datahora_agenda),
				cnpj: String(registro.cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				cpf: String(registro.cpf || '').split(".").join("").replace(".", "").replace("-", ""),
				cep: String(registro.cep || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				telefone_proprietario: String(registro.telefone_proprietario || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				telefone_responsavel: String(registro.telefone_responsavel || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				telefone: String(registro.telefone || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				celular: String(registro.celular || '').split("(").join("").replace(")", "").replace(" ", "").replace(" ", "").replace("-", ""),
				expectativa_anual_s10: FormatUtils.formatarValorBanco(registro.expectativa_anual_s10, 0),
				expectativa_anual_s500: FormatUtils.formatarValorBanco(registro.expectativa_anual_s500, 0),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));

				if (registro.eventoTelaMapa) {
					registro.eventoTelaMapa();
				}
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/prospeccao`, {
				...registro,
				datahora_agenda: DateFormat.formatarDataTelaParaSql(registro.datahora_agenda),
				cnpj: String(registro.cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				cpf: String(registro.cpf || '').split(".").join("").replace(".", "").replace("-", ""),				
				cep: String(registro.cep || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				telefone_proprietario: String(registro.telefone_proprietario || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				telefone_responsavel: String(registro.telefone_responsavel || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				telefone: String(registro.telefone || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				celular: String(registro.celular || '').split("(").join("").replace(")", "").replace(" ", "").replace(" ", "").replace("-", ""),
				expectativa_anual_s10: FormatUtils.formatarValorBanco(registro.expectativa_anual_s10, 0),
				expectativa_anual_s500: FormatUtils.formatarValorBanco(registro.expectativa_anual_s500, 0),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');				
				dispatch(getLista());
				if (registro.cadastroCliente) {
					dispatch(setModoTelaCliente('lista'));
					dispatch(getListaCliente());
				} else {
					dispatch(setModoTela('lista'));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/prospeccao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelectGeral`);
    return {
        type: 'PROSPECCAO_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAtividade() {
    const request = axios.get(`${consts.API_URL}/atividade/listarSelect`);
    return {
        type: 'PROSPECCAO_ATIVIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'PROSPECCAO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'PROSPECCAO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}
