import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './clienteForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
// import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import Map from '../common/map/map';
import Select from '../common/form/select';

import markerAzul from '../assets/mapa/marker_azul.png';
import markerAzulTag from '../assets/mapa/marker_azul_tag.png';
import markerVerde from '../assets/mapa/marker_verde.png';
import markerVerdeTag from '../assets/mapa/marker_verde_tag.png';
import markerAmarelo from '../assets/mapa/marker_amarelo.png';
import markerAmareloTag from '../assets/mapa/marker_amarelo_tag.png';
import markerLaranja from '../assets/mapa/marker_laranja.png';
import markerVermelho from '../assets/mapa/marker_vermelho.png';
import markerVermelhoTag from '../assets/mapa/marker_vermelho_tag.png';
import markerTruckVermelho from '../assets/mapa/marker_truck_vermelho.png';
import markerTruckAzul from '../assets/mapa/marker_truck_azul.png';
import markerTruckVerde from '../assets/mapa/marker_truck_verde.png';
import markerTruckAmarelo from '../assets/mapa/marker_truck_amarelo.png';
import markerTruckLaranja from '../assets/mapa/marker_truck_laranja.png';
import markerTruckVermelhoTerceiro from '../assets/mapa/marker_truck_vermelho_terceiro.png';
import markerTruckAzulTerceiro from '../assets/mapa/marker_truck_azul_terceiro.png';
import markerTruckVerdeTerceiro from '../assets/mapa/marker_truck_verde_terceiro.png';
import markerTruckAmareloTerceiro from '../assets/mapa/marker_truck_amarelo_terceiro.png';
import markerTruckLaranjaTerceiro from '../assets/mapa/marker_truck_laranja_terceiro.png';
import markerPontoPosto from '../assets/mapa/marker_ponto_posto.png';
import markerPontoMecanica from '../assets/mapa/marker_ponto_mecanica.png';
import markerPontoProblemaPista from '../assets/mapa/marker_ponto_problema_pista.png';
import markerPontoTransitoInterrompido from '../assets/mapa/marker_ponto_transito_interrompido.png';
import markerPontoReferencia from '../assets/mapa/marker_ponto_referencia.png';
import markerInterrogacao from '../assets/mapa/marker_interrogacao.png';
import DateFormat from '../common/dateFormat/DateFormat';
import contentCardFooter from '../common/template/contentCardFooter';
import PontoMapaForm from '../pontoMapa/pontoMapaForm';
import ProspeccaoForm from '../prospeccao/prospeccaoForm';

import { 
	setModoTela, initForm, salvar, excluir, getListaEndereco, getListaProspeccao, importar, 
	getListaCidade, getListaCliente, getListaUnidade, getListaUsuario,
	getListaStatusVeiculo, getListaTipoVeiculo, getListaPontoMapa, getListaPosicaoVeiculo
} from './clienteActions';
import {
	initForm as initFormPontoMapa, salvar as salvarPontoMapa,
	getListaTipo as getListaTipoPontoMapa
} from '../pontoMapa/pontoMapaActions';
import {
	initForm as initFormProspeccao, salvar as salvarProspeccao,
	getListaProduto as getListaProdutoProspeccao, getListaCidade as getListaCidadeProspeccao, 
	getListaAtividade as getListaAtividadeProspeccao, getListaUsuario as getListaUsuarioProspeccao
} from '../prospeccao/prospeccaoActions';

class ClienteMapa extends Component {

	state = {
		linhaSelecionada: null,
		id_cidade: null,
		id_cliente: null,
		ultima_compra: 0,
		tipo: [],
		propriedade: null
	}

	componentDidMount() {

	}

    componentWillMount() {
		this.props.getListaCliente();
		this.props.getListaUnidade();
		this.props.getListaEndereco();
		this.props.getListaProspeccao();
		this.props.getListaUsuario();
		this.props.getListaCidade();
		// this.props.getListaStatusVeiculo();
		this.props.getListaTipoVeiculo();
		this.props.getListaPontoMapa();
		this.props.getListaPosicaoVeiculo();
		this.props.getListaTipoPontoMapa();
		this.props.getListaProdutoProspeccao();
		this.props.getListaCidadeProspeccao();
		this.props.getListaAtividadeProspeccao();
		this.props.getListaUsuarioProspeccao();
	}
	
    render() {

		const listaDias = [
			{ id: 0, valor: 'Todos', inicio: 0, fim: 0 }, 
			{ id: 1, valor: 'Até 7 dias', inicio: 0, fim: 7 }, 
			{ id: 2, valor: 'De 8 até 15 dias', inicio: 8, fim: 15 }, 
			{ id: 3, valor: 'De 16 até 30 dias', inicio: 16, fim: 30 }, 
			{ id: 4, valor: 'De 1 mês até 3 meses', inicio: 31, fim: 90 }, 
			{ id: 5, valor: 'De 3 meses até 6 meses', inicio: 91, fim: 180 }, 
			{ id: 6, valor: 'Mais de 6 meses', inicio: 181, fim: 99999 }
		];

		const ultimaCompraSelecionada = listaDias.filter(dia => dia.id != 0 && dia.id == this.state.ultima_compra)[0];

		let codigo = this.props.usuarioCarregado ? this.props.usuarioCarregado.codigo : null;
		let idVendedor = this.props.usuarioCarregado ? this.props.usuarioCarregado.id : null;

		let vendedor = this.props.listaUsuario.filter(item => item.id == this.state.id_usuario)[0];

		let pontos = [];	

		if (!this.state.tipo.length || this.state.tipo.filter(item => item.value == 'cliente').length) {
						
			pontos = this.props.listaEndereco.filter(item => 
				(this.state.id_cidade == null || item.id_cidade == this.state.id_cidade)
				&& (this.state.id_cliente == null || item.id_cliente == this.state.id_cliente)
				&& (vendedor == null || item.codigo_vendedor == vendedor.codigo)
				&& (!ultimaCompraSelecionada || ((item.dias_ultima_compra || 5000) >= ultimaCompraSelecionada.inicio 
					&& (item.dias_ultima_compra || 5000) <= ultimaCompraSelecionada.fim))
				&& (!this.state.propriedade || (this.state.propriedade == 'meu' && codigo == item.codigo_vendedor) 
					|| (this.state.propriedade == 'outro' && codigo != item.codigo_vendedor))
			).map(item => ({
				id: `cliente-${item.id}`,
				nome: `${item.razao_social} - ${item.id_petroshow}\n${item.valor}\n\nCPF/CNPJ: ${item.cnpj || item.cpf || ''}\nTelefone: ${item.telefone || ''}\nCelular: ${item.celular || ''}\nÚltimo Preço S10: ${item.ultimo_preco_s10 || ''}\nÚltimo Preço S500: ${item.ultimo_preco_s500 || ''}\nÚltima Compra: ${item.dias_ultima_compra == null ? '-' : item.dias_ultima_compra} ${item.dias_ultima_compra == null ? '' : (item.dias_ultima_compra == 1 ? 'dia' : 'dias')}\nLocalização: ${item.latitude || ''}, ${item.longitude || ''}`,
				latitude: item.latitude,
				longitude: item.longitude,
				icone: `${item.dias_ultima_compra == null || (ultimaCompraSelecionada && ultimaCompraSelecionada.fim == 0) || item.dias_ultima_compra > 30 ? 'marker-vermelho' : (
					item.dias_ultima_compra > 7 ? 'marker-amarelo' : (
						'marker-verde'
					)
				)}${codigo == item.codigo_vendedor ? '' : '-tag'}`
			}));
		}
		if (!this.state.tipo.length || this.state.tipo.filter(item => item.value == 'prospect').length) {
			this.props.listaProspeccao.filter(item => 
				(this.state.id_cidade == null || item.id_cidade == this.state.id_cidade)
				&& (vendedor == null || item.id_usuario_inclusao == vendedor.id)
				&& (!this.state.propriedade || (this.state.propriedade == 'meu' && idVendedor == item.id_usuario_inclusao) 
					|| (this.state.propriedade == 'outro' && idVendedor != item.id_usuario_inclusao))
			).filter(item => {
				//13- Fábio Henrique Araujo de Freitas
				if (this.props.sessao && this.props.sessao.id == 13) {
					if (![
						5515, 4387, 4795, 1358, 4487, 1003, 3949, 2805, 149, 4633, 686, 5011, 3420,
						951, 3391, 314, 3422, 522, 3914, 309, 3166, 673, 5262, 4182, 5225, 2045, 3420, 3994, 3700, 1900, 153, 3599, 4042, 4276, 986, 2432, 5453, 1492
					].includes(item.id_cidade)) {
						return false;
					}
				}

				//40- LUANNA CONCEICAO MARTINS SILVA
				if (this.props.sessao && this.props.sessao.id == 40) {
					if (![1009, 48, 1292, 3350, 1894, 4170, 4078].includes(item.id_cidade)) {
						return false;
					}
				}
				
				//37- Camila Saraiva
				if (this.props.sessao && this.props.sessao.id == 37) {
					if (![951, 3391, 314, 3422, 522, 3914, 309, 3166, 673, 5262, 4182, 5225, 2045, 3420, 3994, 3700, 1900, 153, 3599, 4042, 4276, 986, 2432, 5453, 1492].includes(item.id_cidade)) {
						return false;
					}
				}

				return true;
			}).forEach(item => {
				if (item.latitude && item.longitude) {
					pontos.push({
						id: `prospect-${item.id}`,
						nome: `${item.razao_social}\n`
							+ `${item.nome_fantasia}\n\n`
							+ `CPF/CNPJ: ${item.cnpj || item.cpf || ''}\n`
							+ (item.telefone_comprador ? `Comprador: ${item.telefone_comprador || ''}\n` : '')
							+ (item.telefone ? `Telefone: ${item.telefone || ''}\n` : '')
							+ (item.celular ? `Celular: ${item.celular || ''}\n` : '')
							+ `Localização: ${item.latitude || ''}, ${item.longitude || ''}`,
						latitude: item.latitude,
						longitude: item.longitude,
						icone: item.potencial_cliente ? 'marker-interrogacao' : (`marker-azul${idVendedor == item.id_usuario_inclusao ? '' : '-tag'}`)
					});
				}
			});
		}

		if (!this.state.tipo.length || this.state.tipo.filter(item => item.value == 'unidade').length) {
			this.props.listaUnidade.forEach(item => {
				if (item.latitude && item.longitude) {
					pontos.push({
						id: `unidade-${item.id}`,
						nome: `${item.valor}\nLocalização: ${item.latitude || ''}, ${item.longitude || ''}`,
						latitude: item.latitude,
						longitude: item.longitude,
						icone: 'marker-home'
					});
				}
			});
		}

		if (!this.state.tipo.length || this.state.tipo.filter(item => item.value == 'veiculo').length) {
			(this.props.listaPosicaoVeiculo || [])
			.filter(item => !item.terceiro && item.telemetria && item.telemetria.latitude && item.telemetria.longitude)
			.forEach(item => {

				pontos.push({
					id: `veiculo-${item.id}`,
					label: `${item.placa} ${item.motorista ? '-' : ''} ${item.motorista || ''}`,
					nome: `${item.placa}\n`
						+ `Tipo: ${item.nome_tipo || '-'}\n`
						+ `Status: ${item.status || 'Sem Status'}\n`
						+ `Motorista: ${item.motorista || ''}\n`
						+ `Odômetro: ${item.telemetria.odometro || ''}\n`
						+ `Ignição: ${item.ignicao ? 'Ligado' : 'Desligado'}\n`
						+ `Velocidade: ${item.telemetria.velocidade || 0} km/h \n`
						+ `Descrição: ${item.telemetria.localizacao || ''}\n`
						+ `Origem/Destino: ${item.telemetria.origem || ''} ${item.telemetria.origem ? '/' : ''} ${item.telemetria.destino || ''}\n`
						+ `Distância: ${parseInt(item.distancia || 0)} km \n`
						+ `Atualização: ${DateFormat.formatarDataHoraSqlParaTela(String(item.telemetria.data_tecnologia).split('-').join('/').substring(0, 16))}\n`
						+ `Localização: ${item.telemetria.latitude || ''},${item.telemetria.longitude || ''}`,
					latitude: item.telemetria.latitude,
					longitude: item.telemetria.longitude,
					icone: `${item.status 
						? (item.status == 'SOBRE RODAS' || item.status == 'EM ENTREGA' || item.status == 'RETORNANDO' ? 'marker-truck-verde' : 'marker-truck-azul') 
						: 'marker-truck-vermelho'
					}${item.nome_tipo && item.nome_tipo.includes('TERCEIRO') ? '-terceiro' : ''}`
				});

			});
		}

		if (!this.state.tipo.length || this.state.tipo.filter(item => item.value == 'ponto').length) {
			this.props.listaPontoMapa.forEach(item => {
				if (item.latitude && item.longitude) {
					pontos.push({
						id: `ponto-${item.id}`,
						nome: `${item.nome}\n${item.nome_tipo}\nLocalização: ${item.latitude || ''}, ${item.longitude || ''}\nObservação: ${item.observacao || ''}`,
						latitude: item.latitude,
						longitude: item.longitude,
						icone: item.icone
					});
				}
			});
		}

        return (
            <div>
				{this.modalCriarPonto()}

                <Content>
					<ContentCard>
						<ContentCardBody>
							<Row>
								{this.props.listaCidade.length > 0 && 
								<Select
									options={this.props.listaCidade}
									label='Cidade'
									cols='12 12 4 2'
									placeholder='Selecione a cidade' 
									value={this.state.id_cidade} 
									onChange={value => this.setState({ ...this.state, id_cidade: value })} />}

								{this.props.listaCliente.length > 0 && 
								<Select
									options={this.props.listaCliente}
									label='Cliente'
									cols='12 12 4 2'
									placeholder='Selecione o cliente' 
									value={this.state.id_cliente} 
									onChange={value => this.setState({ ...this.state, id_cliente: value })} />}
								
								{this.props.listaUsuario.length > 0 && 
								<Select
									options={this.props.listaUsuario.filter(item => item.codigo)}
									label='Vendedor'
									cols='12 12 4 2'
									placeholder='Selecione o usuário' 
									value={this.state.id_usuario} 
									onChange={value => this.setState({ ...this.state, id_usuario: value })} />}
								
								<Select
									options={listaDias}
									label='Última Compra'
									cols='12 12 4 2'
									placeholder='Selecione a última compra' 
									value={this.state.ultima_compra} 
									onChange={value => this.setState({ ...this.state, ultima_compra: value })} />

								<Select
									options={[
										{ id: 'prospect', valor: 'Prospects' },
										{ id: 'cliente', valor: 'Cliente' },
										{ id: 'unidade', valor: 'Unidade' },
										{ id: 'ponto', valor: 'Ponto Referência/Apoio/etc' },
										{ id: 'veiculo', valor: 'Veículo' }
									]}
									label='Tipo'
									cols='12 12 4 2'
									placeholder='Selecione o tipo' 
									multiple={true}
									value={this.state.tipo} 
									onChange={value => this.setState({ ...this.state, tipo: value })} />
								
								<Select
									options={[{ id: 'meu', valor: 'Meus Clientes/Prospects ou sem vendedor' }, { id: 'outro', valor: 'Outros Clientes/Prospects' }]}
									label='Propriedade'
									cols='12 12 4 2'
									placeholder='Selecione a propriedade' 
									value={this.state.propriedade} 
									onChange={value => this.setState({ ...this.state, propriedade: value })} />
							</Row>

							<Map 
								pontos={pontos}
								rota={this.state.rota}
								rotaCarregada={this.state.rotaCarregada}
								setRota={rota => {
									this.setState({
										...this.state,
										rotaCarregada: rota.rotaCarregada,
										rota: {
											pontos: rota ? rota.pontos : [],
											itens: rota ? rota.itens : [],
											overview_polyline: rota.overview_polyline || null
										},
										postos: rota ? rota.pontos : []
									});
								}}
								onClickMapaLatLong={posicao => {
									this.setModalCriarPonto(posicao);
								}} />

							{/* <Table responsive>
								<THead>
									<Tr>
										<Th colspan={2}>Endereços sem Latitude e Longitude</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaEndereco.filter(item => !item.latitude || !item.longitude).map(item => (
										<Tr key={item.id} >
											<Td alignLeft>{item.cnpj ? item.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : item.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</Td>
											<Td>{item.valor}</Td>
										</Tr>
									))}
								</TBody>
							</Table>*/}
						</ContentCardBody>
						<contentCardFooter>
						<Row>
								<div>
									<img src={markerAzul} />
									Prospect
								</div>
								<div>
									<img src={markerAzulTag} />
									Prospect (outro vendedor)
								</div>
								<div>
									<img src={markerInterrogacao} />
									Prospect (cliente em potencial)
								</div>
								<div>
									<img src={markerVerde} />
									Comprou na semana 
								</div>
								<div>
									<img src={markerVerdeTag} />
									Comprou na semana (outro vendedor)
								</div>
								<div>
									<img src={markerAmarelo} />
									Comprou no mês
								</div>
								<div>
									<img src={markerAmareloTag} />
									Comprou no mês (outro vendedor)
								</div>
								<div>
									<img src={markerVermelho} />
									Mais de um mês
								</div>
								<div>
									<img src={markerVermelhoTag} />
									Mais de um mês (outro vendedor)
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckVermelho} />
									Sem Status
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckVermelhoTerceiro} />
									Sem Status (terceiro)
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckAzul} />
									Programado
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckAzulTerceiro} />
									Programado (terceiro)
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckVerde} />
									Sobre Rodas
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckVerdeTerceiro} />
									Sobre Rodas (terceiro)
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerPontoPosto} />
									Posto
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerPontoMecanica} />
									Mecânica
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerPontoProblemaPista} />
									Problemas na Pista
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerPontoTransitoInterrompido} />
									Trânsito Interrompido
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerPontoReferencia} />
									Ponto de Referência
								</div>
							</Row>
						</contentCardFooter>
					</ContentCard>
                </Content>
            </div>
        )
	}

	setModalCriarPonto(posicao) {
		this.setState({
			...this.state,
			novoPontoPosicao: posicao
		});
	}

	modalCriarPonto() {

		if (!this.state.novoPontoPosicao) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Criar Ponto no Mapa</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setModalCriarPonto(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ height: window.innerHeight * 0.8, overflowY: 'scroll' }}>
							<Row>
								<Grid cols='4 4 4 4'>
									<button type='button' 
										class={`btn btn-block btn-dark btn-lg ${this.state.novoPontoPosicao.tipo == 'prospect' ? '' : 'disabled'}`}
										style={{
											cursor: 'pointer'
										}}
										onClick={() => {
											this.setModalCriarPonto({
												...this.state.novoPontoPosicao,
												tipo: 'prospect'
											});

											this.props.initFormProspeccao({
												potencial_cliente: false,
												latitude: this.state.novoPontoPosicao.latitude,
												longitude: this.state.novoPontoPosicao.longitude,
												eventoTelaMapa: () => {
													this.props.getListaProspeccao();
													this.setModalCriarPonto(null);
												}
											});
										}}>Prospect
									</button>
								</Grid>
								<Grid cols='4 4 4 4'>
									<button type='button' 
										class={`btn btn-block btn-dark btn-lg ${this.state.novoPontoPosicao.tipo == 'potencial' ? '' : 'disabled'}`}
										style={{
											cursor: 'pointer'
										}}
										onClick={() => {
											this.setModalCriarPonto({
												...this.state.novoPontoPosicao,
												tipo: 'potencial'
											});

											this.props.initFormProspeccao({
												potencial_cliente: true,
												latitude: this.state.novoPontoPosicao.latitude,
												longitude: this.state.novoPontoPosicao.longitude,
												eventoTelaMapa: () => {
													this.props.getListaProspeccao();
													this.setModalCriarPonto(null);
												}
											});
										}}>Cliente Potencial
									</button>
								</Grid>
								<Grid cols='4 4 4 4'>
									<button type='button' 
										class={`btn btn-block btn-dark btn-lg ${this.state.novoPontoPosicao.tipo == 'ponto' ? '' : 'disabled'}`}
										style={{
											cursor: 'pointer'
										}}
										onClick={() => {
											this.setModalCriarPonto({
												...this.state.novoPontoPosicao,
												tipo: 'ponto'
											});

											this.props.initFormPontoMapa({
												latitude: this.state.novoPontoPosicao.latitude,
												longitude: this.state.novoPontoPosicao.longitude,
												eventoTelaMapa: () => {
													this.props.getListaPontoMapa();
													this.setModalCriarPonto(null);
												}
											});
										}}>Ponto
									</button>
								</Grid>
							</Row>

							<Row style={{ marginTop: 8 }}>
								<Grid cols='12 12 12 12'>
							
									{this.state.novoPontoPosicao.tipo == 'prospect' &&
									<ProspeccaoForm onSubmit={this.props.salvarProspeccao} origemMapa />}

									{this.state.novoPontoPosicao.tipo == 'potencial' &&
									<ProspeccaoForm onSubmit={this.props.salvarProspeccao} origemMapa />}

									{this.state.novoPontoPosicao.tipo == 'ponto' &&
									<PontoMapaForm onSubmit={this.props.salvarPontoMapa} origemMapa />}
								
								</Grid>
							</Row>
						</div>
						<div class='modal-footer justify-content-between' style={{ height: 2, padding: 0, margin: 0 }}>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.cliente.modoTela,
	lista: state.cliente.lista,
	listaCidade: state.cliente.listaCidade,
	listaEndereco: state.cliente.listaEndereco,
	listaProspeccao: state.cliente.listaProspeccao,
	listaCliente: state.cliente.listaCliente,
	listaUnidade: state.cliente.listaUnidade,
	listaUsuario: state.cliente.listaUsuario,
	listaVeiculo: state.cliente.listaVeiculo,
	listaStatusVeiculo: state.cliente.listaStatusVeiculo,
	listaTipoVeiculo: state.cliente.listaTipoVeiculo,
	listaPontoMapa: state.cliente.listaPontoMapa,
	listaPosicaoVeiculo: state.cliente.listaPosicaoVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getListaEndereco, getListaProspeccao, getListaCidade, 
	getListaCliente, getListaUnidade, getListaUsuario, importar,
	getListaStatusVeiculo, getListaTipoVeiculo, getListaPontoMapa, getListaPosicaoVeiculo,
	initFormPontoMapa, salvarPontoMapa, getListaTipoPontoMapa,
	initFormProspeccao, salvarProspeccao,
	getListaProdutoProspeccao, getListaCidadeProspeccao, getListaAtividadeProspeccao, getListaUsuarioProspeccao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteMapa);
