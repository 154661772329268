import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { setModoTela, initForm } from './veiculoActions';

class VeiculoForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={readOnly} />

							<Field
								name='placa'
								component={LabelAndInput}
								label='Placa' placeholder='Informe a placa'
								cols='12 6 3 3'
								readOnly={readOnly} />
								
							<Field
								name='chassi'
								component={LabelAndInput}
								label='Chassi' placeholder='Informe o chassi'
								cols='12 6 3 3'
								readOnly={readOnly} />
								
							<Field
								name='cnpj'
								component={LabelAndInputMask}
								label='CNPJ' placeholder='Informe o CNPJ'
								cols='12 6 3 3'
								mask='99.999.999/9999-99'
								readOnly={readOnly} />
								
							<Field
								name='renavam'
								component={LabelAndInput}
								label='Renavam' placeholder='Informe o renavam'
								cols='12 6 3 3'
								readOnly={readOnly} />
								
							<Field
								name='modelo'
								component={LabelAndInput}
								label='Modelo' placeholder='Informe o modelo'
								cols='12 12 12 12'
								readOnly={readOnly} />
							
							<Field
								name='terceiro'
								component={LabelAndCheckbox}
								label='Terceirizado' placeholder='Informe se é terceirizado'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='media_minima'
								component={LabelAndInputNumber}
								label='Média Mínima' placeholder='Informe a média mínima'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='capacidade'
								component={LabelAndInputNumber}
								label='Capacidade' placeholder='Informe a capacidade'
								cols='12 12 12 12'
								casas={0}
								readOnly={readOnly} />
								
							<Select
								name='compartimentos'
								options={this.props.listaCompartimentos}
								label='Compartimentos'
								cols='12 12 12 12'
								placeholder='Selecione a quantidade de compartimentos'
								readOnly={readOnly}
								value={(this.props.formularioValues.compartimentos || []).length}
								onChange={value => {
									let lista = [];
									for	(let i = 0; i < value; i++) {
										lista.push((this.props.formularioValues.compartimentos || [])[i] || '');
									}
									this.props.initForm({
										...this.props.formularioValues,
										compartimentos: lista
									});
								}} />

							{(this.props.formularioValues.compartimentos || []).map((compartimento, i) => (
								<LabelAndInputNumber key={i}
									name='capacidade'
									label={`Compartimento ${i+1}`} placeholder='Informe a capacidade'
									cols='6 4 3 2'
									readOnly={readOnly}
									value={compartimento}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											compartimentos: this.props.formularioValues.compartimentos.map((item, j) => {
												if (i == j) {
													return data.target.value
												} else {
													return item;
												}
											})
										});
									}} />
							))}

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

VeiculoForm = reduxForm({form: 'veiculoForm', destroyOnUnmount: false})(VeiculoForm);
const mapStateToProps = state => ({
	sessao: state.auth.veiculo,
	formularioValues: getFormValues('veiculoForm')(state),
	registro: state.veiculo.registro,
	listaTipo: state.veiculo.listaTipo,
	listaCompartimentos: state.veiculo.listaCompartimentos
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VeiculoForm);
