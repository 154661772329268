import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import ButtonTable from '../common/table/buttonTable';

import { setModoTela, initForm } from './estoqueManualActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class EstoqueManualForm extends Component {

	//1-ÁGUA BOA, 3-BARRA DO GARÇAS, 9-PORTO ALEGRE DO NORTE
	//1-S10, 3-S500

	state = {
		listaTanques: [
		//1-ÁGUA BOA
		//1-S10
		{
			id: 1,
			nome: 'Tanque 1 de 20m³',
			id_unidade: 1,
			id_produto: 1,
			capacidade: 20000,
			quantidade: 0
		}, {
			id: 2,
			nome: 'Tanque 2 de 20m³',
			id_unidade: 1,
			id_produto: 1,
			capacidade: 20000,
			quantidade: 0
		}, {
			id: 3,
			nome: 'Tanque 3 de 20m³',
			id_unidade: 1,
			id_produto: 1,
			capacidade: 20000,
			quantidade: 0
		}, {
			id: 4,
			nome: 'Tanque 4 de 20m³',
			id_unidade: 1,
			id_produto: 1,
			capacidade: 20000,
			quantidade: 0
		}, 
		//1-ÁGUA BOA
		//3-S500
		{
			id: 5,
			nome: 'Tanque 5 de 20m³',
			id_unidade: 1,
			id_produto: 3,
			capacidade: 20000,
			quantidade: 0
		}, {
			id: 6,
			nome: 'Tanque 6 de 20m³',
			id_unidade: 1,
			id_produto: 3,
			capacidade: 20000,
			quantidade: 0
		},
		//3-BARRA DO GARÇAS
		//1-S10		
		{
			id: 7,
			nome: 'Tanque 3 de 60m³',
			id_unidade: 3,
			id_produto: 1,
			capacidade: 60000,
			quantidade: 0
		}, {
			id: 8,
			nome: 'Tanque 4 de 60m³',
			id_unidade: 3,
			id_produto: 1,
			capacidade: 60000,
			quantidade: 0
		}, {
			id: 9,
			nome: 'Tanque 5 de 60m³',
			id_unidade: 3,
			id_produto: 1,
			capacidade: 60000,
			quantidade: 0
		}, 
		//3-BARRA DO GARÇAS
		//3-S500
		{
			id: 10,
			nome: 'Tanque 1 de 60m³',
			id_unidade: 3,
			id_produto: 3,
			capacidade: 60000,
			quantidade: 0
		}, {
			id: 11,
			nome: 'Tanque 2 de 60m³',
			id_unidade: 3,
			id_produto: 3,
			capacidade: 60000,
			quantidade: 0
		},
		//9-PORTO ALEGRE DO NORTE
		//1-S10		
		{
			id: 12,
			nome: 'Tanque 1 de 60m³',
			id_unidade: 9,
			id_produto: 1,
			capacidade: 60000,
			quantidade: 0
		}, {
			id: 13,
			nome: 'Tanque 2 de 60m³',
			id_unidade: 9,
			id_produto: 1,
			capacidade: 60000,
			quantidade: 0
		}, {
			id: 14,
			nome: 'Tanque 4 de 30m³',
			id_unidade: 9,
			id_produto: 1,
			capacidade: 30000,
			quantidade: 0
		},
		//9-PORTO ALEGRE DO NORTE
		//3-S500
		{
			id: 15,
			nome: 'Tanque 3 de 60m³',
			id_unidade: 9,
			id_produto: 3,
			capacidade: 60000,
			quantidade: 0
		}, {
			id: 16,
			nome: 'Tanque 5 de 30m³',
			id_unidade: 9,
			id_produto: 3,
			capacidade: 30000,
			quantidade: 0
		}]
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
	
							<Field
								name='datahora'
								component={LabelAndInputDateTime}
								label='Data e Hora' placeholder='Informe a data e hora'
								cols='12 6 4 4'
								readOnly={readOnly} />

							<Field
								name='id_unidade'
								component={Select}
								options={this.props.listaUnidade}
								label='Unidade'
								cols='12 12 12 12'
								placeholder='Selecione a unidade'
								readOnly={readOnly} />
								
							<Field
								name='id_produto'
								component={Select}
								options={this.props.listaProduto}
								label='Produto'
								cols='12 12 12 12'
								placeholder='Selecione o produto'
								readOnly={readOnly} />

							{!this.props.petroshow &&
							<>
							{this.state.listaTanques.filter(item => {
								return item.id_unidade == this.props.formularioValues.id_unidade
									&& item.id_produto == this.props.formularioValues.id_produto;
							}).map(tanque => {

								const tanquesSelecionados = tanque.capacidade == 20000 ? this.props.conversao20000 : (
									tanque.capacidade == 30000 ? this.props.conversao30000 : this.props.conversao60000
								);

								return (
									<Select
										key={tanque.id}
										label={`Medida da Régua - ${tanque.nome}`}
										placeholder='Informe a altura'
										options={tanquesSelecionados.map(item => ({ id: item.altura, valor: `${FormatUtils.formatarValorTela(item.altura, 0)} cm => ${item.quantidade} litros` }))}
										cols='12 6 4 3'
										value={tanque.medida_regua}
										onChange={value => {

											let medida = tanquesSelecionados.filter(item => item.altura == value)[0]

											let quantidadeTotal = 0;
											let observacao = '';

											this.setState({
												...this.state,
												listaTanques: this.state.listaTanques.map(tanqueAux => {
													if (tanqueAux.id == tanque.id) {
														quantidadeTotal += medida ? medida.quantidade : 0;
														observacao += medida ? `${tanqueAux.nome}: ${FormatUtils.formatarValorTela(medida.altura, 0)} cm - ${medida.quantidade} litros \n` : '';
														return {
															...tanqueAux,
															medida_regua: value,
															quantidade: medida ? medida.quantidade : 0
														};
													} else {
														quantidadeTotal += tanqueAux.quantidade;
														observacao += tanqueAux.medida_regua != null ? `${tanqueAux.nome}: ${FormatUtils.formatarValorTela(tanqueAux.medida_regua, 0)} cm - ${tanqueAux.quantidade} litros \n` : '';
														return tanqueAux
													}
												})
											});

											//Sobre Rodas
											quantidadeTotal += parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.quantidade_sobre_rodas, 0) || 0);
											observacao += `Sobre Rodas: ${this.props.formularioValues.quantidade_sobre_rodas || 0} litros \n`;

											this.props.initForm({
												...this.props.formularioValues,
												quantidade: quantidadeTotal,
												observacao: observacao
											});
										}} />
								);
							})}
							</>}

							{!this.props.petroshow &&
							<LabelAndInputNumber
								name='quantidade_sobre_rodas'
								label='Sobre Rodas' placeholder='Informe a quantidade'
								cols='13 6 4 3'
								casas={0}
								value={this.props.formularioValues.quantidade_sobre_rodas}
								onChange={data => {

									let quantidadeTotal = 0;
									let observacao = '';

									this.state.listaTanques.forEach(tanqueAux => {
										quantidadeTotal += tanqueAux.quantidade;
										observacao += tanqueAux.medida_regua != null ? `${tanqueAux.nome}: ${FormatUtils.formatarValorTela(tanqueAux.medida_regua, 0)} cm - ${tanqueAux.quantidade} litros \n` : '';
									});

									//Sobre Rodas
									quantidadeTotal += parseFloat(FormatUtils.formatarValorBanco(data.target.value, 0) || 0);
									observacao += `Sobre Rodas: ${data.target.value || 0} litros \n`;

									this.props.initForm({
										...this.props.formularioValues,
										quantidade_sobre_rodas: data.target.value,
										quantidade: quantidadeTotal,
										observacao: observacao
									});
								}} />}

							<Field
								name='quantidade'
								component={LabelAndInputNumber}
								label='Quantidade' placeholder='Informe a quantidade'
								cols='12 12 12 12'
								casas={0}
								readOnly={this.props.petroshow ? readOnly : 'readOnly'} />

							<Field
								name='observacao'
								component={LabelAndTextarea}
								label='Observação (medida da régua)' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={this.props.petroshow ? readOnly : 'readOnly'}
								rows={5} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

EstoqueManualForm = reduxForm({form: 'estoqueManualForm', destroyOnUnmount: false})(EstoqueManualForm);
const mapStateToProps = state => ({
	sessao: state.auth.estoqueManual,
	formularioValues: getFormValues('estoqueManualForm')(state),
	registro: state.estoqueManual.registro,
	listaProduto: state.estoqueManual.listaProduto,
	listaUnidade: state.estoqueManual.listaUnidade,
	conversao20000: state.estoqueManual.conversao20000,
	conversao30000: state.estoqueManual.conversao30000,
	conversao60000: state.estoqueManual.conversao60000
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EstoqueManualForm);
