import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Endereco from './prospeccaoEvento/prospeccaoEvento';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import { setError } from '../common/toast/toast';

import {
	setModoTela, initForm, setModoTelaCliente
} from './prospeccaoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class ProspeccaoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.prospeccaoEvento || this.props.excluir ? 'readOnly' : '';

		if (!this.props.prospeccaoEvento) {
			return (
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								{!this.props.cliente &&
								<Field
									name='potencial_cliente'
									component={LabelAndCheckbox}
									label='Cliente em Potencial (agendar visita)' placeholder='Selecione'
									cols='12 12 12 12'
									readOnly={readOnly} />}
									
								{(!this.props.formularioValues.potencial_cliente && !this.props.cliente) &&
								<Field
									name='id_petroshow'
									component={LabelAndInput}
									label='ID Petroshow' placeholder='Informe o ID Petroshow'
									cols='12 12 12 12'
									readOnly={readOnly} />}
									
								{!this.props.formularioValues.potencial_cliente &&
								<Field
									name='razao_social'
									component={LabelAndInput}
									label='Razão Social' placeholder='Informe a razão social'
									cols='12 12 12 12'
									readOnly={readOnly} />}

								{!this.props.formularioValues.potencial_cliente &&
								<Field
									name='nome_fantasia'
									component={LabelAndInput}
									label='Nome Fantasia' placeholder='Informe o nome fantasia'
									cols='12 12 12 12'
									readOnly={readOnly} />}

								{this.props.formularioValues.potencial_cliente &&	
								<Field
									name='razao_social'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 12 12'
									readOnly={readOnly} />}

								{!this.props.formularioValues.potencial_cliente &&
								<>

								<Field
									name='id_tipo'
									component={Select}
									options={this.props.listaTipo}
									label='Tipo'
									cols='12 12 12 12'
									placeholder='Selecione o tipo'
									readOnly={readOnly} />

								{this.props.formularioValues.id_tipo == 1 ? (
									<Field
										name='cpf'
										component={LabelAndInputMask}
										label='CPF' placeholder='Informe o CPF'
										cols='12 12 12 12'
										mask='999.999.999-99'
										readOnly={readOnly} />
								) : (
									<Field
										name='cnpj'
										component={LabelAndInputMask}
										label='CNPJ' placeholder='Informe o CNPJ'
										cols='12 12 12 12'
										mask='99.999.999/9999-99'
										readOnly={readOnly} />
								)}
								
								<Field
									name='inscricao_estadual'
									component={LabelAndInput}
									label='IE' placeholder='Informe a IE'
									cols='12 6 6 6'
									readOnly={readOnly} />

								<Field
									name='id_atividade'
									component={Select}
									options={this.props.listaAtividade}
									label='Ramo de Atividade'
									cols='12 6 6 6'
									placeholder='Selecione a atividade'
									readOnly={readOnly} />
															
								<Field
									name='endereco'
									component={LabelAndInput}
									label='Endereço' placeholder='Informe o endereço'
									cols='12 6 6 6'
									readOnly={readOnly} />
									
								<Field
									name='complemento'
									component={LabelAndInput}
									label='Complemento' placeholder='Informe o complemento'
									cols='12 2 2 2'
									readOnly={readOnly} />
									
								<Field
									name='numero'
									component={LabelAndInput}
									label='Número' placeholder='Informe o número'
									cols='12 2 2 2'
									readOnly={readOnly} />
									
								<Field
									name='bairro'
									component={LabelAndInput}
									label='Bairro' placeholder='Informe o bairro'
									cols='12 4 4 4'
									readOnly={readOnly} />
									
								<Field
									name='id_cidade'
									component={Select}
									options={this.props.listaCidade}
									label='Cidade'
									cols='12 4 4 4'
									placeholder='Selecione a cidade'
									readOnly={readOnly} />
									
								<Field
									name='cep'
									component={LabelAndInputMask}
									label='CEP' placeholder='Informe o CEP'
									cols='12 4 4 4'
									mask='99999-999'
									readOnly={readOnly} />

								</>}
							</Row>
							<Row>

								<Field
									name='latitude'
									component={LabelAndInput}
									label='Latitude' placeholder='Informe a latitude'
									cols='12 4 4 3'
									readOnly={readOnly} />
								
								<Field
									name='longitude'
									component={LabelAndInput}
									label='Longitude' placeholder='Informe a longitude'
									cols='12 4 4 3'
									readOnly={readOnly} />

								<Grid cols='12 4 3 3' 
									style={{ 
										marginTop: window.innerWidth >= 576 ? 28 : null,
										marginBottom: window.innerWidth >= 576 ? 0 : 8,
										paddingRight: 12
									}}>
									<Button
										text='Buscar Localização'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => this.getLocalizacao()} />
								</Grid>

							</Row>
							
							{!this.props.formularioValues.potencial_cliente &&
							<Row>

								<Field
									name='observacao'
									component={LabelAndInput}
									label='Observação' placeholder='Informe a observação'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='nome_contato'
									component={LabelAndInput}
									label='Nome do Contato' placeholder='Informe o nome'
									cols='12 6 3 3'
									readOnly={readOnly} />
									
								<Field
									name='email'
									component={LabelAndInput}
									label='E-mail do Contato' placeholder='Informe o e-mail'
									cols='12 6 3 3'
									readOnly={readOnly} />
									
								<Field
									name='telefone'
									component={LabelAndInputMask}
									label='Telefone do Contato' placeholder='Informe o telefone'
									cols='12 6 3 3'
									mask='(99) 9999-9999'
									readOnly={readOnly} />

								<Field
									name='celular'
									component={LabelAndInputMask}
									label='Celular do Contato' placeholder='Informe o celular'
									cols='12 6 3 3'
									mask='(99) 9 9999-9999'
									readOnly={readOnly} />
									
								<Field
									name='telefone_proprietario'
									component={LabelAndInputMask}
									label='Celular do Proprietário' placeholder='Informe o celular'
									cols='12 6 3 3'
									mask='(99) 9 9999-9999'
									readOnly={readOnly} />
									
								<Field
									name='telefone_responsavel'
									component={LabelAndInputMask}
									label='Celular do Responsável' placeholder='Informe o celular'
									cols='12 6 3 3'
									mask='(99) 9 9999-9999'
									readOnly={readOnly} />
																
								<Field
									name='produto'
									component={LabelAndInput}
									label='Produto' placeholder='Informe o produto'
									cols='12 6 3 3'
									readOnly={readOnly} />

								<Field
									name='prazo'
									component={LabelAndInput}
									label='Prazo' placeholder='Informe o prazo'
									cols='12 6 3 3'
									readOnly={readOnly} />

								<Field
									name='area'
									component={LabelAndInput}
									label='Área' placeholder='Informe a área'
									cols='12 6 3 3'
									readOnly={readOnly} />

								<Field
									name='abrindo_area'
									component={LabelAndInput}
									label='Abrindo Área' placeholder='Informe se está abrindo área'
									cols='12 6 3 3'
									readOnly={readOnly} />

								<Field
									name='fornecedor'
									component={LabelAndInput}
									label='Fornecedor' placeholder='Informe o fornecedor'
									cols='12 6 6 6'
									readOnly={readOnly} />

								<Field
									name='expectativa_anual_s10'
									component={LabelAndInputNumber}
									label='Expectativa Anual S10 (m³)' placeholder='Informe a expectativa'
									cols='12 6 4 3'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='expectativa_anual_s500'
									component={LabelAndInputNumber}
									label='Expectativa Anual S500 (m³)' placeholder='Informe a expectativa'
									cols='12 6 4 3'
									casas={0}
									readOnly={readOnly} />

									
								<Field
									style={{ marginTop: 10 }}
									name='safrinha'
									component={LabelAndCheckbox}
									label='Safrinha' placeholder='Informe se é safrinha'
									cols='12 12 12 12'
									readOnly={readOnly} />
							
								{this.props.formularioValues.safrinha &&
								<Field
									name='area_safrinha'
									component={LabelAndInput}
									label='Área da Safrinha' placeholder='Informe a área da safrinha'
									cols='12 6 3 3'
									readOnly={readOnly} />}

							</Row>}
							
							{!this.props.formularioValues.potencial_cliente &&
							<Row>
										
								<Select
									options={Array.from(Array(10).keys()).map(item => ({ id: item + 1, valor: item + 1 }))}
									label='Tanques'
									cols='12 12 8 6'
									placeholder='Selecione a quantidade de tanques'
									readOnly={readOnly}
									value={(this.props.formularioValues.tanques || []).length}
									onChange={value => {
										let lista = [];
										for	(let i = 0; i < value; i++) {
											lista.push((this.props.formularioValues.tanques || [])[i] || { id_produto: null, capacidade: null });
										}
										this.props.initForm({
											...this.props.formularioValues,
											tanques: lista
										});
									}} />
							</Row>}

							{!this.props.formularioValues.potencial_cliente &&
							<>

							{(this.props.formularioValues.tanques || []).map((tanque, i) => (
								<Row key={i}>
									<Select
										options={this.props.listaProduto}
										name='produto'
										label={`Produto`} placeholder='Selecione o produto'
										cols='6 7 5 4'										
										readOnly={readOnly}
										value={tanque.id_produto}
										onChange={data => {
											this.props.initForm({
												...this.props.formularioValues,
												tanques: this.props.formularioValues.tanques.map((item, j) => {
													if (i == j) {
														return {
															...item,
															id_produto: data
														}
													} else {
														return item;
													}
												})
											});
										}} />

									<LabelAndInputNumber
										name='capacidade'
										label={`Capacidade`} placeholder='Informe a capacidade'
										cols='6 5 3 2'
										readOnly={readOnly}
										value={tanque.capacidade}
										casas={0}
										onChange={data => {
											this.props.initForm({
												...this.props.formularioValues,
												tanques: this.props.formularioValues.tanques.map((item, j) => {
													if (i == j) {
														return {
															...item,
															capacidade: FormatUtils.formatarValorBanco(data.target.value)
														}
													} else {
														return item;
													}
												})
											});
										}} />
								</Row>
							))}
							
							</>}
								
							<Row>
								<Field
									name='id_usuario_inclusao'
									component={Select}
									options={this.props.listaUsuario}
									label='Vendedor'
									cols='12 12 12 12'
									placeholder='Selecione o vendedor'
									readOnly={readOnly} />
							</Row>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								{!this.props.origemMapa &&
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.cliente ? this.props.setModoTelaCliente('lista') : this.props.setModoTela('lista')} />
								</Grid>}
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			)
		} else {

			return (
				<>
					<ContentCard>
						<Form event={this.props.handleSubmit}>
							<ContentCardBody>
								<Row>
									<Field
										name='nome'
										component={LabelAndInput}
										label='Nome' placeholder='Informe o nome'
										cols='12 12 12 12'
										readOnly={'readOnly'} />
										
									<Field
										name='nome_contato'
										component={LabelAndInput}
										label='Nome do Contato' placeholder='Informe o nome'
										cols='12 6 3 3'
										readOnly={readOnly} />
										
									<Field
										name='email'
										component={LabelAndInput}
										label='E-mail' placeholder='Informe o e-mail'
										cols='12 6 3 3'
										readOnly={readOnly} />
										
									<Field
										name='telefone'
										component={LabelAndInputMask}
										label='Telefone' placeholder='Informe o telefone'
										cols='12 6 3 3'
										mask='(99) 9999-9999'
										readOnly={readOnly} />

									<Field
										name='celular'
										component={LabelAndInputMask}
										label='Celular' placeholder='Informe o celular'
										cols='12 6 3 3'
										mask='(99) 9 9999-9999'
										readOnly={readOnly} />
								</Row>
								<Row>
									<Field
										name='datahora_agenda'
										component={LabelAndInputMask}
										label='Agendamento' placeholder='Informe a data'
										cols='12 6 3 3'
										mask='99/99/9999' />

									<Grid cols='12 6 4 3' style={{ marginTop: 26 }}>
										<Button
											text='Salvar Agenda'
											submit
											type={'success'}
											icon={'fa fa-check'} />
									</Grid>
								
								</Row>
							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='6 6 6 6'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.cliente ? this.props.setModoTelaCliente('lista') : this.props.setModoTela('lista')} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</Form>
					</ContentCard>
					
					<Endereco id_prospeccao={this.props.formularioValues.id} />

				</>
			);
		}
    }

	getLocalizacao() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				
				this.props.initForm({
					...this.props.formularioValues,
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				});
				
			}, error => {
				setError(`Erro ao obter localização: ${error.message}`)
			  }
			);
		  } else {
				setError(`Geolocalização não é suportada neste navegador.`);
		  }
	}

}

ProspeccaoForm = reduxForm({form: 'prospeccaoForm', destroyOnUnmount: false})(ProspeccaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('prospeccaoForm')(state),
	registro: state.prospeccao.registro,
	listaCidade: state.prospeccao.listaCidade,
	listaAtividade: state.prospeccao.listaAtividade,
	listaTipo: state.prospeccao.listaTipo,
	listaUsuario: state.prospeccao.listaUsuario,
	listaProduto: state.prospeccao.listaProduto
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, setModoTelaCliente
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProspeccaoForm);
