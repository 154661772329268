import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { 
	getLista
} from './dashboardClienteActions';

import contentCardBody from '../common/template/contentCardBody';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

class Dashboard extends Component {

	state = {
		pesquisar: '',
		pedido: {}
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
		this.props.getLista();
    }

    render() {

        return (
			<Content>
				<label>Pedidos</label>

				{this.modalPedido()}

				{this.props.lista.map(pedido => {

					let corCard = pedido.nome_status == 'Entregue' ? '#03ab33' : (pedido.nome_status == 'Em Entrega' ? '#0469cf' : '#d99c02');

					return (
						<ContentCard key={pedido.id_item}>
							<ContentCardHeader style={{ backgroundColor: corCard }}>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div style={{ fontSize: 10, fontWeight: 'bold', color: '#ffffff' }}>
										Pedido
									</div>
									<div style={{ fontSize: 10, fontWeight: 'bold', color: '#ffffff', textAlign: 'right' }}>
										Status
									</div>
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div style={{ fontSize: 12, fontWeight: 'bold', color: '#ffffff' }}>
										{DateFormat.formatarDataSqlParaTela(pedido.data_pedido)}
									</div>
									<div style={{ fontSize: 12, fontWeight: 'bold', color: '#ffffff', textAlign: 'right' }}>
										{pedido.nome_status}
									</div>
								</div>
							</ContentCardHeader>
							<contentCardBody>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Produto
											</div>
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Quantidade
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.nome_produto}
											</div>
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												{FormatUtils.formatarValorTelaDecimal(pedido.quantidade, 0)} L
											</div>
										</div>
									</Grid>
								</Row>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Motorista
											</div>
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Placa
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.nome_motorista || 'Não Informado'}
											</div>
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												{pedido.placa_veiculo}
											</div>
										</div>
									</Grid>
								</Row>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Representante Comercial
											</div>
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Contato
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.nome_vendedor}
											</div>
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												<a href={`https://api.whatsapp.com/send/?phone=55${pedido.whatsapp_vendedor}&text&type=phone_number&app_absent=0`} target='blank'><i class='fab fa-whatsapp' style={{ marginRight: 4 }}></i>{FormatUtils.formatarTelefone(pedido.whatsapp_vendedor)}</a>
											</div>
										</div>
									</Grid>
								</Row>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Ordem da Entrega
											</div>
											{(pedido.nome_status == 'Em Entrega' && pedido.latitude && pedido.longitude) &&
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Localização da Entrega
											</div>}
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.ordem}ª entrega
											</div>
											{(pedido.nome_status == 'Em Entrega' && pedido.latitude && pedido.longitude) &&
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												<a href={`https://www.google.com/maps/place/${pedido.latitude},${pedido.longitude}`} target='blank'><i class='fas fa-map-marker-alt' style={{ marginRight: 4 }}></i>Ver no Mapa</a>
											</div>}
										</div>
									</Grid>
								</Row>
							</contentCardBody>

							<a id='back-to-top'
								class='btn btn-primary back-to-top'
								role='button'
								style={{ borderRadius: '50%', width: 50, height: 50, paddingTop: 12, paddingLeft: 11 }}
								onClick={() => {
									this.setDialogPedido(true);
								}}>
								<i class='fas fa-plus' style={{ fontSize: 24, padding: 0, margin: 0, color: '#fff' }}></i>
							</a>

						</ContentCard>
					);
				})}
			</Content>
        )
	}

	setDialogPedido(open) {
		this.setState({
			...this.state,
			dialogAberto: open,
			pedido: {}
		});
	}

	modalPedido() {

		if (!this.state.dialogAberto) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Solicitar Cotação</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setDialogPedido(false)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: '80%' }}>
							<Row>
								<Select
									options={[{ id: 'Diesel S10', valor: 'Diesel S10' }, { id: 'Diesel S500', valor: 'Diesel S500' }]}
									cols='12 12 12 12'
									label='Produto'
									placeholder='Selecione o produto'
									value={this.state.pedido.produto}
									onChange={value => {
										this.setState({
											...this.state,
											pedido: {
												...this.state.pedido,
												produto: value
											}
										});
									}} />
																		
								<LabelAndInputNumber
									label='Quantidade (litros)' 
									placeholder='Informe o quantidade'
									cols='12 4 4 4'
									casas={0}
									value={this.state.pedido.quantidade}
									onChange={data => {
										this.setState({
											...this.state,
											pedido: {
												...this.state.pedido,
												quantidade: data.target.value
											}
										});
									}} />

								<LabelAndTextarea
									label='Observação' 
									placeholder='Faça alguma observação'
									cols='12 12 12 12'
									value={this.state.pedido.observacao}
									onChange={data => {
										this.setState({
											...this.state,
											pedido: {
												...this.state.pedido,
												observacao: data.target.value
											}
										});
									}} />				
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
						
							<button type='button' 
								class='btn btn-primary'	
								disabled={!this.state.pedido.produto || !this.state.pedido.quantidade}
								onClick={() => {

									let pedido = this.props.lista[0];
									let whatsapp = `55${pedido && pedido.whatsapp_vendedor ? pedido.whatsapp_vendedor : '55991735243'/*'66992418700'*/}`;
									let texto = `*Solicitação de Cotação:* \n\n`
										+ `*Cliente:* ${this.props.sessao.nome} \n`
										+ `*Produto:* ${this.state.pedido.produto} \n`
										+ `*Quantidade:* ${this.state.pedido.quantidade} litros \n`
										+ (this.state.pedido.observacao ? 
											`*Observação:* \n`
											+ `${this.state.pedido.observacao} \n`
										: '')
									window.open(`https://api.whatsapp.com/send/?phone=${whatsapp}&text=${encodeURI(texto)}&type=phone_number&app_absent=0`, '_blank', 'noreferrer');

									this.setDialogPedido(false);
								}}>Enviar Solicitação</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboardCliente.aguardando,
	lista: state.dashboardCliente.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
