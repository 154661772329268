import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'TELEMETRIA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('telemetriaForm', registro)
    ];
}

export function getLista(dataInicial, dataFinal) {
    const request = axios.get(`${consts.API_URL}/telemetria?data_inicial=${DateFormat.formatarDataTelaParaParametro(dataInicial)}&data_final=${DateFormat.formatarDataTelaParaParametro(dataFinal)}`);
    return {
        type: 'TELEMETRIA_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'TELEMETRIA_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'TELEMETRIA_VEICULO_SELECT_LISTADO',
        payload: request
    };
}
